<template>
  <main class="page-login">
    <section class="box-auth">
      <img class="logo" alt="logo" src="@/assets/icon_nyami.svg" />
      <h1 class="main-title">INICIAR SESSIÓ</h1>
      <p class="auth-intro">
        Obre la sessió i mira què toca menjar avui!
      </p>

      <form>
        <div class="form-group">
          <label class="form-label" for="username">Email</label>
          <input
            v-model="email"
            placeholder="Correu electrònic"
            type="email"
            id="email"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label class="form-label" for="password">Contrasenya</label>
          <input
            v-model="password"
            placeholder="Contrasenya"
            type="password"
            id="password"
            class="form-control"
            required
          />
        </div>
        <button v-on:click.prevent="login" class="btn btn-inverse btn-md">
          Vinga
        </button>
      </form>

      <p class="auth-bottom">
        Encara no t'has registrat?
        <router-link to="/Register" >Fes-ho ara</router-link>
      </p>
      <p class="auth-bottom">
        No recordes la contrasenya?
        <a href="" @click.prevent="resetEmail">Canvia-la</a>
      </p>
    </section>
  </main>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import swal from 'sweetalert';

export default {
  name: "login",
  data: function () {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async resetEmail(){
      if(this.email!=""){
        
        const reset = await swal({
          title: "Compte!", 
          text: "Vols rebre un mail per reestablir la contrasenya?",
          icon: "warning",
          buttons: true,       
        })
        if(reset){
          const auth = getAuth();
          sendPasswordResetEmail(auth, this.email)
          .then(() => {
            swal("Enviat!","El missatge de canvi de contrasenya s'acaba d'enviar", "success");            
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode + errorMessage);
            swal("No anem bé!","Aquest mail no coincideix amb el de cap usuari", "error");
          });
        }
      }else{
        swal("No anem bé!","Per canviar de contrasenya has d'introduir el mail", "error");
      }
      
    },
    //Inicia la sessió d'usuari
    
    async login() {
    try {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, this.email, this.password)
        this.$router.push("/");
    } catch (e) {
      swal("No anem bé!","Nom d'usuari o contrasenya incorrecte", "error");
        console.error(e);
    } 
}
    
  },
};
</script>



<style scoped lang="scss">
a{
    color:$primary;
    text-decoration:none;
}
a:hover{
    color:$primary;
    text-decoration: none;
}
</style>