<template>
    <div class="container d-flex justify-content-center mt-5">
        <div class="col-md-6 mt-5 d-flex flex-column align-items-center">
            <img class="logo" alt="logo" src="@/assets/icon_nyami.svg" />
            <h1 class="text-center mb-4">AVÍS LEGAL</h1>
            <div class="mb-4">
                <h2>CONDICIONS D'ÚS</h2>
                <p>L'accés a aquesta aplicació implica l'acceptació d'aquestes condicions d'ús que regulen l'accés i la utilització de la mateixa per tal de posar a disposició dels usuaris informació sobre la nostra activitat.</p>
                <p>Es prohibeix expressament la utilització dels continguts d'aquest lloc web per a la seva utilització amb fins comercials o per a la seva distribució, transformació o comunicació.</p>
                <p><span>nyami</span> no respondrà de cap conseqüència, dany o perjudici que poguessin derivar d'aquesta utilització o ús de la informació.</p>
                <p>Tant l'accés a aquesta aplicació com l'ús que pugui fer de la informació continguda en la mateixa és de l'exclusiva responsabilitat de qui ho realitza. L'usuari s'obliga a no utilitzar la informació que es publica en aquest web amb fins o efectes illícits o lesius, a no danyar o inutilitzar la informació i a no realitzar qualsevol altra acció que pugui ser contrària al contingut d'aquest Avís Legal.</p>
                <p><span>nyami</span> no pot assegurar la inexistència d'interrupcions o errors en l'accés a aquesta aplicació, tot i que posarà els seus majors esforços per evitar-los.</p>            
            </div>
            <div class="mb-4">
                <h2>COOKIES</h2>
                <p>Aquesta Web actualment no utilitza “Cookies” d'ús intern però es reserva el dret d'utilitzar-ne previ avís en aquesta secció i amb l'autorització dels usuaris.</p>
                <p>Les “Cookies” són petites quantitats d'informació que s'emmagatzemen en l'ordinador amb l'única fi de gestionar l'autenticació dels usuaris, la prestació dels serveis sol·licitats i efectuar la transmissió de comunicacions per la Xarxa, d'acord amb l'establert en l'article 22 de la Llei 34/2012 de Serveis de la Societat de la Informació (LSSI).</p>
                <p>L'usuari té la possibilitat de no instal·lar les “Cookies” en el seu disc dur, configurar el navegador per ser informat abans que les “Cookies” s'emmagatzemin en el seu ordinador o per eliminar les que s'hagin instal·lat. L'usuari pot configurar el seu navegador per especificar les “Cookies” que poden ser acceptades i les que no, pot també acceptar “Cookies” de sessió, de manera que s'eliminin quan es tanqui el navegador i podent, en qualsevol moment, veure i esborrar les “Cookies” individualment</p>
            </div>
            <div class="mb-4">
                <h2>POLÍTICA DE PROTECCIÓ DE DADES</h2>
                <p>En compliment del REGLAMENT (UE) 2016/679 DEL PARLAMENT EUROPEU I DEL CONSELL de 27 d'Abril de 2016, relatiu a la protecció de les persones físiques pel que fa al tractament de dades personals i a la lliure circulació d'aquestes dades i la seva normativa de desenvolupament, se li informa que les dades personals proporcionades per vostè seran tractades amb les següents finalitats:</p>
                <ul class="mb-3">
                    <li>- Donar contestació i gestionar les seves consultes, comentaris i suggeriments, si s'escau.</li>
                    <li>- Tenir un registre dels usuaris de l'aplicació</li>
                </ul>
                <p>Mitjançant l'emplenament de formularis de contacte de l'aplicació o mitjançant l'enviament de correus electrònics o qualsevol altre tipus de sol·licitud d'informació remesa a <span>nyami</span>, l'interessat dóna el seu consentiment exprés per al tractament de les seves dades personals.</p>
                <p>En cap cas <span>nyami</span> utilitzarà les dades personals dels interessats per a fins diferents dels anteriorment esmentats, ni les comunicarà a tercers sense el consentiment previ i exprés de l'afectat, i es compromet a establir les mesures tècniques i organitzatives necessàries per salvaguardar la informació conforme als requeriments que estableix l'esmentat Reglament. Les seves dades personals es conservaran en els nostres registres durant un període de tres anys. No obstant això, en qualsevol moment vostè pot demanar l'accés a les seves dades personals i la seva rectificació o supressió. Així com, limitar el seu tractament, o directament oposar-se al tractament o exercir el dret a la portabilitat dels mateixos.</p>
                <p>També li informem del seu dret a presentar una reclamació davant l'Agència Espanyola de Protecció de Dades, si considera que en el tractament de les seves dades no s'estan respectant els seus drets.</p>
            </div>
            <div class="mb-4">
                <h2>INFORMACIÓ LEGAL</h2>
                <p>En compliment amb el deure d'informació recollit en article 10 de la Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i del Comerç Electrònic, <span>nyami</span>, li informa que les dades aquí consignades corresponen a l'entitat titular del lloc web</p>
                <ul>
                    <li>DENOMINACIÓ: nyami</li>
                    <li>DOMICILI:</li>
                    <li>TELÈFON:</li>
                    <li>EMAIL: nyami.dietplanning@gmail.com</li>
                </ul>
            </div>
            <div class="mb-4">
                <h2>LEGISLACIÓ APLICABLE I COMPETÈNCIA JURISDICCIONAL</h2>
                <p>El present Avís Legal es regeix per la normativa espanyola vigent que li és d'aplicació.</p>
                <p>Per a la resolució de les controvèrsies que es puguin derivar com a conseqüència del que disposen les presents disposicions i sobre la seva interpretació, aplicació i compliment. L'usuari, en virtut de l'acceptació de les condicions recollides en aquest Avís legal, renuncia expressament a qualsevol altre fur que pogués correspondre-li.</p>                
            </div>            
        </div>      
    </div>               
</template>

<script>
export default {
  name: "LegalWarning",
};
</script>

<style scoped lang="scss">
.logo{
      width:120px;
      margin-bottom: 20px;
    }
span{
    color:$primary;
    font-weight: 800;
}
</style>