<template>
    <div class="container mt-5">
        <div class="mt-5 text-center">
            <h1>CONSELLS DE L'ONA I LA VINYET</h1>
            <p>Aquí podeu trobar les fruites i verdures de temporada del mes actual<span v-if="this.$store.state.isLoggedIn">, o sigui, {{actualMonthName}}</span></p>
            <p v-if="this.$store.state.isLoggedIn" class="comment">(Els elements seguits d'aquest símbol <img class="icon" src="../assets/icons/in_out.svg"/>  estan entrant o sortint de temporada)</p>            
        </div>
        <div class="d-flex text-center flex-column mt-5" v-if="!this.$store.state.isLoggedIn">
            <p>Aquesta secció només està disponible pels usuaris registrats</p>
            <router-link to="/Register"><button class="btn btn-inverse btn-md"> Registra't</button></router-link>
        </div>
        <div class="row">
            <div v-for="advice in filteredAdvices" v-bind:key="advice" class="col-4 col-lg-2"> 
                <Advice :element="advice"></Advice>
            </div>
            <div v-for="advice in filteredLeavingSeasonAdvices" v-bind:key="advice" class="col-4 col-lg-2"> 
                <Advice :element="advice" :leavingSeason="true"></Advice>
            </div>                
        </div>
        <div class="d-flex justify-content-center text-center">
            <div class="col-md-6 addAdvice" v-if="this.$store.state.admin">
                <p>AFEGIR ELEMENT</p>
                <form v-on:submit.prevent="createAdvice">
                    <div class="form-group mb-4">
                        <label class="form-label">Nom</label>
                        <input
                            v-model="advice.name"                        
                            type="text"                        
                            class="form-control"
                            required
                        />
                    </div>
                    <div class="form-group mb-4">   
                        <label class="form-label">Seleccionar imatge</label>
                        <input
                            v-on:change="putMyFile"
                            type="file"                        
                            class="form-control"                
                        />
                        <img
                            class="mt-3"
                            v-if="myFile != null"
                            :src="advice.image_url"                        
                            height="200"
                        />
                    </div>                          
                    <div class="form-group mb-4">
                        <label class="form-label" >Mesos de temporada</label>
                        <div class="d-flex flex-wrap gap-3">
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="1"                            
                                />
                                Gener
                            </p>
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="2"                            
                                />
                                Febrer
                            </p> 
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="3"                            
                                />
                                Març
                            </p> 
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="4"                            
                                />
                                Abril
                            </p>
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="5"                            
                                />
                                Maig
                            </p>
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="6"                            
                                />
                                Juny
                            </p> 
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="7"                            
                                />
                                Juliol
                            </p> 
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="8"                            
                                />
                                Agost
                            </p>
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="9"                            
                                />
                                Setembre
                            </p>
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="10"                            
                                />
                                Octubre
                            </p> 
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="11"                            
                                />
                                Novembre
                            </p> 
                            <p>
                                <input
                                v-model="advice.season"
                                class="form-check-input"
                                type="checkbox"
                                value="12"                            
                                />
                                Desembre
                            </p>              
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <label class="form-label" >Mesos entrant o sortint de temporada</label>
                        <div class="d-flex flex-wrap gap-3">
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="1"                            
                                />
                                Gener
                            </p>
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="2"                            
                                />
                                Febrer
                            </p> 
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="3"                            
                                />
                                Març
                            </p> 
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="4"                            
                                />
                                Abril
                            </p>
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="5"                            
                                />
                                Maig
                            </p>
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="6"                            
                                />
                                Juny
                            </p> 
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="7"                            
                                />
                                Juliol
                            </p> 
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="8"                            
                                />
                                Agost
                            </p>
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="9"                            
                                />
                                Setembre
                            </p>
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="10"                            
                                />
                                Octubre
                            </p> 
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="11"                            
                                />
                                Novembre
                            </p> 
                            <p>
                                <input
                                v-model="advice.leaving_season"
                                class="form-check-input"
                                type="checkbox"
                                value="12"                            
                                />
                                Desembre
                            </p>              
                        </div>
                    </div>
                    <button type="submit" class="mt-2 btn btn-inverse btn-md">Crear</button>
                </form>
            </div>
        </div>
        
                     
    </div>
    
      
    
  
</template>

<script>
import Advice from "@/components/Advice.vue";
import { addAdvice } from "@/db";
import { getAllAdvices } from "@/db";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
    name: "Advices",
    data: function () {
        return {
            myFile: null,
            advice:{
                name:"",
                image_url:"",
                season:[],
                leaving_season:[]
            },
            advices:[],            
            actualMonth:"",
            actualMonthName:""
        }
    },
    components: {
        Advice
    },
    computed: {        
        filteredAdvices() {
            let filAdv = this.advices;    
            
            filAdv = filAdv.filter((item) => {                                  
                return item.season.includes(this.actualMonth.toString());                    
            });                
            return filAdv;
        },
        filteredLeavingSeasonAdvices() {
            let filAdv = this.advices;    
            
            filAdv = filAdv.filter((item) => {                                  
                return item.leaving_season.includes(this.actualMonth.toString());                    
            });                
            return filAdv;
        }
    }, 
    async created(){
        this.advices= await getAllAdvices();
        var d = new Date();
        this.actualMonth=d.getMonth()+1;
        var months=["Gener", "Febrer", "Març", "Abril", "Maig", "Juny", "Juliol", "Agost", "Setembre", "Octubre", "Novembre", "Desembre"];
        this.actualMonthName=months[d.getMonth()];
    },
    methods:{
        //carrega l'arxiu i en guarda la URL local
        putMyFile(event) {
            this.myFile = event.target.files[0];
            this.advice.image_url = URL.createObjectURL(this.myFile);            
        },
        async createAdvice(){
            this.advice.image_url = await this.uploadFile();
            await addAdvice(this.advice);
            this.myFile=null;
            this.advice={};
            this.advice.name="";
            this.advice.image_url="";            
            this.advice.season=[];
            this.advice.leaving_season=[];
        },
        //carrega una imatge a Storage i en reotorna la URL
        async uploadFile() {
            const storage = getStorage();
            const storageRef = ref(storage, "images/" + this.myFile.name);
            await uploadBytes(storageRef, this.myFile);
            const fileUrl = await getDownloadURL(storageRef);            
            return fileUrl;
        },
  }
};
</script>

<style scoped lang="scss">
.comment{
    font-size:10px;
    
  }
.icon{
    width:50px
}
.row div{
    margin-bottom: 10px;
}

.addAdvice{
    border: $primary 1px solid;
    border-radius: 10px;    
    padding: 10px;
}
</style>