<template>    
    <div class="card">
        <img class="optimize card-img-top" width="640" height="426" v-bind:src="element.image_url" :alt="element.name" >
        <div class="card-body d-flex flex-column align-items-center">            
            <p class="card-title text-center">{{element.name}}</p>
            <img v-if="leavingSeason" class="icon"  src="../assets/icons/in_out.svg"/>                          
        </div>
    </div>    
</template>

<script>
export default {
  name: "Advice",
  props: {
    element: Object,
    leavingSeason: Boolean      
  },    
};
</script>

<style scoped lang="scss">

.icon{
    width:50px
}
.card{
  height: calc(100% - 15px);
}
</style>