import "@/firebase";

import { getFirestore } from "firebase/firestore";

import {
  collection,  
  addDoc,  
  getDocs,  
  query,
  where,
  doc,
  setDoc,
  deleteDoc,
  getDoc,  
  updateDoc
} from "firebase/firestore";

export default db;

const db = getFirestore(); 

// Inicialitza la base de dades de cada usuari com a no administrador
export async function newUserDB(userID,userMail){
  //crea l'espai de l'usuari a la base de dades (no adminstrador i mostra informació)
  await setDoc(doc(db, "users", userID),{admin:false, showInformation:true}); 
  //afegeix el mail de l'usuari a la llista de mails
  await setDoc(doc(db, "mails", userMail),{}); 
  //recull els ingredients d'admin
  const ingredients = [];
  const queryIngredients = await getDocs(collection(db, "ingredients"));
  queryIngredients.forEach((doc) => {
     ingredients.push(doc.data());
  });
  //els afegeix a la llista d'ingredients de l'usuari
  ingredients.forEach((ingredient)=>{
    addDoc(collection(db, "users", userID, "ingredients"),ingredient);
  });
  //recull les receptes d'admin
  const recipes = [];
  const queryRecipes = await getDocs(collection(db, "recipes"));
  queryRecipes.forEach((doc) => {
    recipes.push(doc.data());
  }); 
  //les afegeix a la llista de receptes de l'usuari
  recipes.forEach((recipe)=>{
    addDoc(collection(db, "users", userID, "recipes"),recipe);
  });      
}

//Consulta si l'usuari és administrador
export async function isAdmin(userID){   
  const docRef = doc(db,"users", userID);
  const docSnap = await getDoc(docRef);  
  const isAdmin=docSnap.data().admin;  
  return isAdmin;  
}

//Consulta si l'usuari vol veure la informació d'ajuda
export async function wantInformation(userID){   
  const docRef = doc(db,"users", userID);
  const docSnap = await getDoc(docRef);  
  const showInformation=docSnap.data().showInformation;  
  return showInformation;  
}

//Canvia la voluntat de veure la informació d'ajuda
export async function noInformation(userID){   
  const docRef = doc(db,"users", userID);  
  await updateDoc(docRef, { showInformation: false});  
}


//Comprova si el mail és d'algun usuari
export async function correctMail(mail){
  const docRef = doc(db, "mails", mail);
  const docSnap = await getDoc(docRef);
  return docSnap.exists();
}

//crea un document de compartició
export async function createSharedDocument(mail,userMail){
  await setDoc(doc(db, "share", mail),{});
  await setDoc(doc(db, "share", mail, "whoShare", userMail),{});  
}

//Comprova si t'han compartit elements
export async function sharedThings(mail){   
  const docRef = doc(db, "share", mail);
  const docSnap = await getDoc(docRef);  
  return docSnap.exists();
}

//retorna els mails de les persones que han compartit la seva dieta amb el mail passat com a paràmetre
export async function peopleWhoShare(mail){
  const mails = [];
  const querySnapshot = await getDocs(collection(db, "share",mail,"whoShare"));
  querySnapshot.forEach((doc) => {
    mails.push(doc.id);
  });
  return mails;
}
//actualitza events, ingredients i receptes amb els compartits
export async function actualizeSharedThings(userMail,sharerMail,userID,admin){ 
  
  //afegeix els events compartits i els borra  
  const sharedEvents = [];
  const sharedEventsID= [];
  const eventsCollection = await getDocs(collection(db, "share", userMail, "whoShare", sharerMail,"calendar"));
  eventsCollection.forEach((doc) => {
    sharedEvents.push(doc.data()); 
    sharedEventsID.push(doc.id);   
  });  
  for(var i=0;i<sharedEvents.length;i++){  
    await addEvent(userID,sharedEvents[i]);
    await deleteDoc(doc(db, "share", userMail, "whoShare",sharerMail, "calendar",sharedEventsID[i]));     
  } 
  //afegeix les receptes que no siguin a la base de dades i les borra
  const recipes= await getAllRecipes(admin,userID);
  const sharedRecipes = [];
  const sharedRecipesID = [];
  const recipesCollection = await getDocs(collection(db, "share", userMail, "whoShare", sharerMail,"recipes"));
  recipesCollection.forEach((doc) => {
    sharedRecipes.push(doc.data());  
    sharedRecipesID.push(doc.id);      
  });  
  for(var j=0;j<sharedRecipes.length;j++) {
    var existsRecipe=false;
    for(var k=0;k<recipes.length;k++){
      if(sharedRecipes[j].name==recipes[k].name){
        existsRecipe=true;
      }
    } 
    if(!existsRecipe){
      await addRecipe(admin,userID,sharedRecipes[j]);
    }
    await deleteDoc(doc(db, "share", userMail, "whoShare",sharerMail, "recipes",sharedRecipesID[j]));      
  } 
  //afegeix els ingredients que no siguin a la base de dades i els borra
  const ingredients= await getAllIngredients(admin,userID);
  const sharedIngredients = [];
  const sharedIngredientsID = [];
  const ingredientsCollection = await getDocs(collection(db, "share", userMail, "whoShare", sharerMail,"ingredients"));
  ingredientsCollection.forEach((doc) => {
    sharedIngredients.push(doc.data());
    sharedIngredientsID.push(doc.id);     
  });    
  for(var l=0;l<sharedIngredients.length;l++) {
    var existsIngredient=false;
    for(var m=0;m<ingredients.length;m++){
      if(sharedIngredients[l].name==ingredients[m].name){
        existsIngredient=true;
      }
    } 
    if(!existsIngredient){
      await addIngredient(admin,userID,sharedIngredients[l]);
    }
    await deleteDoc(doc(db, "share", userMail, "whoShare",sharerMail, "ingredients",sharedIngredientsID[l]));      
  }
  //borra el document compartit    
  await deleteDoc(doc(db, "share", userMail, "whoShare",sharerMail)); 
}

//recull tots els ingredients de la base de dades i els retorna com a array
export async function getAllIngredients(admin,userID) {
  if(admin){
    const ingredients = [];
    const querySnapshot = await getDocs(collection(db, "ingredients"));
    querySnapshot.forEach((doc) => {
      ingredients.push(doc.data());
    });
    //ordena alfabèticament
    ingredients.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    }); 
    return ingredients;
  }else{
    const ingredients = [];
    const querySnapshot = await getDocs(collection(db, "users", userID, "ingredients"));
    querySnapshot.forEach((doc) => {
      ingredients.push(doc.data());
    });
    //ordena alfabèticament
    ingredients.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    }); 
    return ingredients;
  }
  
}

//recull totes les fruites i verdures dels consells de la base de dades i les retorna com a array
export async function getAllAdvices() {
  const advices = [];
  const querySnapshot = await getDocs(collection(db, "advices"));
    querySnapshot.forEach((doc) => {
      advices.push(doc.data());
    });
    //ordena alfabèticament
    advices.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    }); 
    return advices;
  
}

//recull totes les receptes de la base de dades i les retorna com a array
export async function getAllRecipes(admin,userID) {
  const recipes = [];
  if(admin){    
    const querySnapshot = await getDocs(collection(db, "recipes"));
    querySnapshot.forEach((doc) => {
      recipes.push(doc.data());
    });
    return recipes;
  }else{    
    const querySnapshot = await getDocs(collection(db, "users", userID, "recipes"));
    querySnapshot.forEach((doc) => {
      recipes.push(doc.data());
    });
    return recipes;
  }
}

//recull tots els events del calendari de la base de dades i els retorna com a array ordenat per ordre d'àpat
export async function getAllEvents(userID) {
  const events = [];
  const querySnapshot = await getDocs(collection(db, "users", userID, "calendar"));
  querySnapshot.forEach((doc) => {
    events.push(doc.data());
  });
  //ordena per ordre d'àpats
  events.sort(function (a, b) {        
    if (a.class=="esmorzar"&&(b.class=="dinar"||b.class=="berenar"||b.class=="sopar")) {
      return -1;
    }
    if (a.class=="dinar"&&(b.class=="berenar"||b.class=="sopar")) {
      return -1;
    }
    if (a.class=="dinar"&&b.class=="esmorzar") {
      return 1;
    }
    if (a.class=="berenar"&&(b.class=="esmorzar"||b.class=="dinar")) {
      return 1;
    }
    if (a.class=="berenar"&&b.class=="sopar") {
      return -1;
    }
    if (a.class=="sopar"&&(b.class=="esmorzar"||b.class=="dinar"||b.class=="berenar")) {
      return 1;
    }        
    return 0;
  }); 
  return events;
}

//recull totes les botigues de la base de dades i les retorna com a array
export async function getAllShops(userID) {
  const shops = [];
  const querySnapshot = await getDocs(collection(db, "users", userID, "shops"));
  querySnapshot.forEach((doc) => {
    shops.push(doc.data());
  });
  return shops;
}

//recull tots els elments que no formen part de receptes i els retorna com a array
export async function getAllElements(userID) {
  const elements = [];
  const querySnapshot = await getDocs(collection(db, "users", userID, "no_recipe_elements"));
  querySnapshot.forEach((doc) => {
    elements.push(doc.data());
  });
  return elements;
}

//retorna la recepta amb el nom passat com a paràmetre
export async function getRecipe(admin,userID,name) {
  if(admin){
    const recipes = [];
    const q = query(collection(db,"recipes"), where("name", "==", name));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      recipes.push(doc.data());
    });  
    return recipes[0];
  }else{
    const recipes = [];
    const q = query(collection(db,"users", userID, "recipes"), where("name", "==", name));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      recipes.push(doc.data());
    });  
    return recipes[0];
  }
  
}

//retorna la recepta amb el nom passat com a paràmetre
export async function getIngredient(admin,userID,name) {
  if(admin){
    const ingredients = [];
    const q = query(collection(db, "ingredients"), where("name", "==", name));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      ingredients.push(doc.data());
    });
    return ingredients[0];
  }else{
    const ingredients = [];
    const q = query(collection(db,"users", userID, "ingredients"), where("name", "==", name));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      ingredients.push(doc.data());
    });
    return ingredients[0];
  }
  
}

//afegeix una nova fruita o verdura als consells de la base de dades
export async function addAdvice(advice) {    
    await addDoc(collection(db, "advices"), advice);     
}

//afegeix un nou ingredient a la base de dades
export async function addIngredient(admin,userID,ingredient) {  
  if(admin){
    await addDoc(collection(db, "ingredients"), ingredient); 
  }else{
    await addDoc(collection(db, "users", userID, "ingredients"),ingredient);
  }  
} 

//afegeix un nou ingredient als ingredients per compartir
export async function addIngredientToShare(mail,userMail,ingredient) { 
  await addDoc(collection(db, "share", mail, "whoShare", userMail, "ingredients"),ingredient);  
}

//afegeix una nova recepta a la base de dades
export async function addRecipe(admin,userID,recipe) {  
  if(admin){
    await addDoc(collection(db, "recipes"), recipe); 
  }else{
    await addDoc(collection(db, "users", userID, "recipes"),recipe);
  }  
}

//modifica la recepta a la base de dades
export async function updateRecipe(admin,userID,recipe) {  
  if(admin){
    const recipesRef = [];
    const q = query(collection(db,"recipes"), where("name", "==", recipe.name));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {      
      recipesRef.push(doc.id);
    });      
    const docToUpdate = doc(db,"recipes",recipesRef[0]);      
    await updateDoc(docToUpdate, recipe); 
  }else{
    const recipesRef = [];
    const q = query(collection(db,"users", userID, "recipes"), where("name", "==", recipe.name));
    const querySnapshot = await getDocs(q);    
    querySnapshot.forEach((doc) => {      
      recipesRef.push(doc.id);
    });     
    const docToUpdate = doc(db,"users", userID, "recipes",recipesRef[0]);      
    await updateDoc(docToUpdate, recipe);    
  }
  
} 

//afegeix una nova recepta a les receptes per compartir
export async function addRecipeToShare(mail,userMail,recipe) {     
  await addDoc(collection(db, "share", mail, "whoShare", userMail, "recipes"),recipe);  
} 

//afegeix una nova entrada al calendari de la base de dades
export async function addEvent(userID,event) {  
  await addDoc(collection(db, "users", userID, "calendar"),event);
}

//afegeix una nova entrada al calendari de comparticions
export async function addEventToShare(mail,userMail,event) {  
  await addDoc(collection(db, "share", mail, "whoShare", userMail, "calendar"),event);  
} 


//afegeix una nova botiga a la base de dades
export async function addShop(userID,shop) {  
  await addDoc(collection(db, "users", userID, "shops"),shop);
} 

//afegeix un nou element a la llista d'elements que no formen part de les receptes
export async function addElement(userID,element) {  
  await addDoc(collection(db, "users", userID, "no_recipe_elements"),element);
}

//elimina un element passat com a paràmetre de la llista d'elements que no formen part de les receptes
export async function deleteElement(userID,element) {    
  const q = query(collection(db, "users", userID, "no_recipe_elements"), where("name", "==", element.name));
  const querySnapshot = await getDocs(q);
  var elementsToDeleteID=[];
  querySnapshot.forEach((doc) => {
    elementsToDeleteID.push(doc.id);
  });  
  await deleteDoc(doc(db, "users", userID, "no_recipe_elements",elementsToDeleteID[0]));  
}

//elimina la recepta amb el nom passat com a paràmetre
export async function deleteRecipe(admin,userID,recipeName) { 
  var recipesToDeleteID=[];
  if(admin){
    const q = query(collection(db, "recipes"), where("name", "==", recipeName));
    const querySnapshot = await getDocs(q);    
    querySnapshot.forEach((doc) => {
      recipesToDeleteID.push(doc.id);
    });  
    await deleteDoc(doc(db, "recipes", recipesToDeleteID[0]));    
  }else{
    const q = query(collection(db, "users",userID,"recipes"), where("name", "==", recipeName));
    const querySnapshot = await getDocs(q);    
    querySnapshot.forEach((doc) => {
      recipesToDeleteID.push(doc.id);
    });  
    await deleteDoc(doc(db, "users",userID, "recipes", recipesToDeleteID[0])); 
  }
}

//elimina un event del calendari passat com a paràmetre
export async function deleteEvent(userID,title,moment,start) { 
  var eventsToDeleteID=[];
  
    const q = query(collection(db, "users",userID,"calendar"), where("title", "==", title),where("class", "==", moment),where("start", "==", start));
    const querySnapshot = await getDocs(q);    
    querySnapshot.forEach((doc) => {
      eventsToDeleteID.push(doc.id);
    });  
    await deleteDoc(doc(db, "users",userID, "calendar", eventsToDeleteID[0])); 
  
}



