<template>
  <div class="vld-parent">
        <loading v-model:active="isLoading"/>         
  </div>
  <div class="container">
    <div  class="botom-navegation d-flex justify-content-center gap-4 fixed-bottom">
      <router-link to="/home/day">
        <button class="botom-btn" aria-label="day"><img src="../assets/icons/icon_day.svg" height ="50" width="50" alt="icono dia" /></button>
      </router-link>
      <router-link to="/home/month">
        <button class="botom-btn" aria-label="month"><img src="../assets/icons/icon_month.svg" height ="50" width="50" alt="icono mes"  /></button>        
      </router-link>
      <router-link to="/shoppingList">
        <button class="botom-btn active" aria-label="shopping list">
          <img src="../assets/icons/icon_shopping.svg" height ="50" width="50" alt="icono carret" />
        </button>
      </router-link>
      <router-link to="/share">
        <button class="botom-btn" aria-label="share">
            <img src="../assets/icons/icon_share.svg" height ="50" width="50" alt="icono compartir" />
          </button>
      </router-link>                
    </div>
    <div class="row ">
      <div class="col-md-6 text-center d-flex flex-column justify-content-center">
        <h1>LLISTA DE LA COMPRA</h1>
        <p>Selecciona els dies que vols que s'afegeixin a la llista de la compra</p>
        <p class="mt-2">També pots afegir les botigues on compraràs els ingredients</p>
       
        <div class="d-flex justify-content-center">
          
            <form v-on:submit.prevent="addNewShop">
              <div  class="d-flex align-items-center">
                <div class="form-group">                                          
                  <input
                    v-model="newShop.name"
                    placeholder="Nom de la botiga"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>                                                                 
                <button type="submit" class="btn btn-inverse btn-md">Afegir</button>
              </div>
            </form>
          
          
        </div>
      </div>
      <div class="col-md-6 mt-2">
        <DateRange v-bind:events="events" v-on:selectedDays="makeShoppingList" ></DateRange>
      </div>      
      <div v-if="ingredients.length!=0" class="col-12 mt-3 p-0">
               
        <table class="table table-sm ">
          <thead>
            <tr>
              <th scope="col">Ingredient</th>
              <th scope="col">Quantitat</th>
              <th scope="col">Unitat</th>              
              <th scope="col">Botiga</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ingredient in ingredients" v-bind:key="ingredient">                 
              <Ingredient @removeIngredient="removeIngredient" @updatedIngredient="updateIngredients" :shops="shops" :ingredient="ingredient"></Ingredient>
            </tr>
          </tbody>
        </table>
                
      </div>
      <div class="col-12 my-2 p-0 text-center">
        <p>Si vols afegir algun element directament a la llista també ho pots fer</p>
        <form class="no_recipe_elements d-flex justify-content-center gap-2" @submit.prevent="addNewElement">
          <input type="text" placeholder="Element" v-model="newElement.name" required>
          <input type="number" placeholder="Quantitat" v-model="newElement.quantity" required min="0" step="any" >
          <input type="text" placeholder="Unitat" v-model="newElement.mesure_unit" required>
          <select name="shop" v-model="newElement.shopToBuy">
            <option v-for="shop in shops" v-bind:key="shop" :value="shop.name">{{shop.name}}</option>                                     
          </select> 
          <input type="submit" value="+" class="btn btn-sm btn-inverse" >           
        </form>              
      </div>
      <div class="col-12 my-2 p-0 text-center">
        <ul>
          <li v-for="element in no_recipe_elements" v-bind:key="element">
            {{element.name}} {{element.quantity}} {{element.mesure_unit}} {{element.shopToBuy}}
            <button class="btn btn-sm btn-inverse" @click="deleteThisElement(element)">x</button> 
          </li>
        </ul>
      </div>
      <div v-if="updatedIngredients.length!=0">
        <ul>
          <li v-for="shop in shops" v-bind:key="shop">
            <div class="mb-3" v-if="updatedIngredients.map(item => item.shopToBuy).indexOf(shop.name)!=-1">
              <strong>{{shop.name}}</strong>
              <a class="whatsapp" :href="messageToSend(shop.name)"><img class="mx-2" src="../assets/icons/icon_whatsapp.png" alt="Logo WhatsApp"></a>
              <a  :href="messageToSendMail(shop.name)"><img class="mail mx-2" src="../assets/icons/icon_mail.svg" alt="Logo Mail"></a>
            </div>
            
            <ul>
              <li v-for="item in updatedIngredients" v-bind:key="item">
                <p v-if="item.shopToBuy==shop.name">
                  <input class="form-check-input" type="checkbox">
                   {{item.name}} {{item.quantity}} {{item.mesure_unit}}                  
                </p>                 
              </li>
            </ul>            
          </li>
        </ul>
      </div> 
    </div>    
    
  </div>
  
</template>

<script>

import DateRange from "@/components/DateRange.vue";
import Ingredient from "@/components/Ingredient.vue";
import { getAllEvents } from "@/db";
import { getRecipe } from "@/db"; 
import { addShop } from "@/db"; 
import { getAllShops } from "@/db";
import { getAllElements } from "@/db";
import { addElement } from "@/db"; 
import { deleteElement } from "@/db";
import swal from 'sweetalert';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
 
export default {
  name: "ShoppingList",
  data() {
    return {   
      isLoading:false,   
      events:[],      
      ingredients:[],
      newShop:{
        name:""
      },      
      shops:[],
      updatedIngredients:[], 
      no_recipe_elements:[],
      newElement:{
        name:"",
        quantity:0,
        mesure_unit:"",
        shopToBuy:""
      }       
    };
  },
  components: {
    DateRange,
    Ingredient,
    Loading  
  },
    
  methods:{
    messageToSend(shop){      
      var message="whatsapp://send?text=Llista de la compra "+shop+"%0A%0A";
      
      for(var i=0;i<this.updatedIngredients.length;i++){
          if(this.updatedIngredients[i].shopToBuy==shop){            
            message=message+this.updatedIngredients[i].name+"%20"+this.updatedIngredients[i].quantity+"%20"+this.updatedIngredients[i].mesure_unit+"%0A";              
          }
        }      
      return message;
    },
    messageToSendMail(shop){      
      var message="mailto:?subject=Llista de la compra "+shop+"%0A%0A&body=";      
      for(var i=0;i<this.updatedIngredients.length;i++){
          if(this.updatedIngredients[i].shopToBuy==shop){            
            message=message+this.updatedIngredients[i].name+"%20"+this.updatedIngredients[i].quantity+"%20"+this.updatedIngredients[i].mesure_unit+"%0A";              
          }
        }      
      return message;
    },
    removeIngredient(ingredient){
      var isHere=-1;
        for(var i=0;i<this.updatedIngredients.length;i++){
          if(this.updatedIngredients[i].name==ingredient.name&&this.updatedIngredients[i].mesure_unit==ingredient.mesure_unit){
            isHere=i; 
          }
        }
        this.updatedIngredients.splice(isHere,1);           
    },
    updateIngredients(updatedIngredient){      
        var isHere=-1;
        for(var i=0;i<this.updatedIngredients.length;i++){
          if(this.updatedIngredients[i].name==updatedIngredient.name&&this.updatedIngredients[i].mesure_unit==updatedIngredient.mesure_unit){
            isHere=i;                                      
          } 
        }        
        if(isHere==-1){
          this.updatedIngredients.push(updatedIngredient);                   
        }else{          
          this.updatedIngredients.splice(isHere,1);
          this.updatedIngredients.push(updatedIngredient);
        }  
        this.updatedIngredients.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });                                                                                                
    }, 
    async makeShoppingList(selectedDays){ 
      this.isLoading=true; 
      if(selectedDays.length==0){
        this.updatedIngredients=[];
        this.no_recipe_elements.forEach(element => this.updatedIngredients.push(element));
      }
      //rep els dies i crea un array de noms de recepta                  
      var recipeNameList=[];
      for(var j=0;j<this.events.length;j++){      
        for(var i=0;i<selectedDays.length;i++){
          if(this.events[j].start==selectedDays[i]){
            recipeNameList.push(this.events[j].title);            
          }
        }                        
      }      
      //crea un array de receptes a partir de l'array de noms      
      var recipesList=[];
      for(var k=0;k<recipeNameList.length;k++){        
        var actualRecipe= await getRecipe(this.$store.state.admin,this.$store.state.userUid,recipeNameList[k]);        
        if(actualRecipe==null){
          swal("Tenim un problema", recipeNameList[k] + " no existeix a la base de dades", "error");
        }else{
          recipesList.push(actualRecipe);
        }
         
      }
      //crea un array d'ingredients sense distingir si estan repetits           
      var repeatedIngredients=[];
      for(var l=0;l<recipesList.length;l++){        
        for(var m=0;m<recipesList[l].ingredients.length;m++){          
          repeatedIngredients.push(recipesList[l].ingredients[m]);          
          }                 
      }      
      
      //elimina els repetits i fa la suma de quantitats (si no tenen la mateixa unitat de mesura manté els dos)
      this.ingredients = repeatedIngredients.reduce((acc, curr) => {
        const exists = acc.find(item => item.name === curr.name && item.mesure_unit.substr(0,3) === curr.mesure_unit.substr(0,3) );
        if (exists) {
          exists.quantity += curr.quantity;
          return acc;
        }
        return [...acc, curr]
      }, []);
      
      //ordena alfabèticament
      this.ingredients.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });     
      
      this.isLoading=false; 
    },
    async addNewShop(){
      await addShop(this.$store.state.userUid,this.newShop);
      swal("Fet!", this.newShop.name +" s'ha afegit a la teva llista de botigues", "success");
      this.newShop={};
      this.newShop.name="";
      this.shops=await getAllShops(this.$store.state.userUid); 
    },
    async addNewElement(){
      await addElement(this.$store.state.userUid,this.newElement);
      this.updatedIngredients.push(this.newElement);
      this.newElement={};
      this.newElement.name="";
      this.newElement.quantity=0;
      this.newElement.mesure_unit="";
      this.newElement.shopToBuy="";      
      this.no_recipe_elements=await getAllElements(this.$store.state.userUid); 
    },
    async deleteThisElement(element){           
      var deleteThisElement= await swal({
        title: "Sí?",
        text: "Vols borrar aquest element de forma definitiva?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      if (deleteThisElement) {          
        swal("L'element s'ha borrat!", {icon: "success",}); 
        await deleteElement(this.$store.state.userUid,element);        
        var isHere=-1;
        for(var i=0;i<this.updatedIngredients.length;i++){
          if(this.updatedIngredients[i].name==element.name){
            isHere=i; 
          }
        }
        this.updatedIngredients.splice(isHere,1);        
        this.no_recipe_elements=await getAllElements(this.$store.state.userUid);         
      }                  
    } 
  },
 
  async created(){
    this.events=await getAllEvents(this.$store.state.userUid);
    this.shops=await getAllShops(this.$store.state.userUid); 
    this.no_recipe_elements=await getAllElements(this.$store.state.userUid);
    this.no_recipe_elements.forEach(element => this.updatedIngredients.push(element));
    this.updatedIngredients.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
    }); 
    
  },
};
</script>


<style scoped lang="scss">
.mail{
  width:30px;
  height:30px;
}

@media (min-width: 668px) { .whatsapp { display: none } }

thead{
  border-bottom:2px solid $primary;
}
tbody tr{
  border-bottom:1px solid $secundary;
}

table, .no_recipe_elements{
  font-size: 12px;   
}
@media (min-width: 544px) {  
  table, .no_recipe_elements {font-size:14px;} 
}

.no_recipe_elements input[type="number"] {
   width:40px;
  }

.no_recipe_elements input[type="text"] {
   width:90px;
  }

@media (min-width: 544px) {  
  .no_recipe_elements input[type="text"] {
   width:130px;
  }
  .no_recipe_elements input[type="number"] {
   width:60px;
  }
}




.botom-navegation{
  background-color: $primary;
  padding-bottom: 5px;
}
.botom-btn{
  background-color: inherit;
  border:0px;
  
}
.active{
  border-top: 5px solid white;
  border-radius: 5px;
  
}
// Estils
.row{
    margin-top: 80px;
}
</style>