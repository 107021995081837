<template>
    <div class="vld-parent">
        <loading v-model:active="isLoading"/>         
    </div>
    <div class="container">
        <div  class="botom-navegation d-flex justify-content-center gap-4 fixed-bottom">
            <router-link to="/home/day">
                <button class="botom-btn" aria-label="day"><img src="../assets/icons/icon_day.svg" height ="50" width="50" alt="icono dia"  /></button>
            </router-link>
            <router-link to="/home/month">
                <button class="botom-btn" aria-label="month"><img src="../assets/icons/icon_month.svg" height ="50" width="50" alt="icono mes"  /></button>        
            </router-link>
            <router-link to="/shoppingList">
                <button class="botom-btn" aria-label="shopping list">
                    <img src="../assets/icons/icon_shopping.svg" height ="50" width="50" alt="icono carret"/>
                </button>
            </router-link>
            <router-link to="/share">
                <button class="botom-btn active" aria-label="share">
                    <img src="../assets/icons/icon_share.svg" height ="50" width="50" alt="icono compartir" />
                </button>
            </router-link>
        </div>
        <div class="row">
            <div class="col-md-6 text-center d-flex flex-column justify-content-center">
                <h1>COMPARTIR LA DIETA</h1>
                <p class="mt-2">Introdueix el mail de l'usuari a qui li vols enviar</p>
                <div class="d-flex justify-content-center">          
                    <form v-on:submit.prevent="selectMail">
                        <div  class="d-flex align-items-center">
                            <div class="form-group">                                                   
                            <input
                                v-model="mail"
                                placeholder="Mail de l'usuari"
                                type="text"
                                class="form-control"
                                required
                            />
                            </div>                                                                 
                            <button type="submit" class="btn btn-inverse btn-md">Seleccionar</button>
                        </div>
                    </form>          
                </div>
                <p>{{confirmedMail}}</p>
                <p class="mt-4">Selecciona els dies que vols compartir amb un altre usuari</p>
            </div>
            <div class="col-md-6 mt-2">
                <DateRange v-bind:events="events" v-on:selectedDays="putDays"></DateRange>
            </div>  
            <div class="mt-4 col-12 d-flex flex-column align-items-center">
                <p v-if="days.length>0&&confirmedMail!=''">Compartir {{days.length}} <span v-if="days.length!=1">dies</span><span v-if="days.length==1">dia</span>  amb {{confirmedMail}}</p>
                    <div>
                        <button class="btn btn-inverse btn-md" v-on:click.prevent="shareDiet">Compartir</button>           
                    </div>
                </div>
        </div>
    </div>
</template>  

<script>

import DateRange from "@/components/DateRange.vue";
import { getAllEvents } from "@/db";
import { correctMail } from "@/db";
import { addEventToShare } from "@/db";
import { addRecipeToShare } from "@/db";
import { getRecipe } from "@/db";
import { getIngredient } from "@/db";
import { addIngredientToShare } from "@/db";
import { createSharedDocument } from "@/db";
import swal from 'sweetalert';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  name: "Share",
  data() {
    return {  
      isLoading:false,    
      events:[],
      mail:"",
      confirmedMail:"",
      days:[]       
    };
  },
  components: {
    DateRange, 
    Loading    
  },  
  methods:{ 
        async selectMail(){    
            var isCorrect=await correctMail(this.mail);        
            if(isCorrect){                
                this.confirmedMail=this.mail;
                this.mail="";
            }else{                
                swal("Alerta!","Aquest mail no coincideix amb el de cap usuari!", "error");
            }
            if(this.confirmedMail==this.$store.state.userMail){                
                swal("Error!","No pots compartir la dieta amb tu mateix!", "error");
                this.confirmedMail="";
            }
        },
        putDays(selectedDays){
            this.days=selectedDays;
        },
        async shareDiet(){            
            if(this.confirmedMail==""){                
                swal("Compte!","Per poder compartir la dieta has d'introduir un mail vàlid!", "warning");
            }else if(this.days.length==0){                              
                swal("Compte!","Per poder compartir la dieta has de seleccionar algun dia!", "warning");
            }else{
                this.isLoading=true;                
                //crea el document de compartició
                await createSharedDocument(this.confirmedMail,this.$store.state.userMail);
                //rep els dies, afegeix els events als events per compartir i crea un array de noms de recepta
                var recipesList=[];
                for(var j=0;j<this.events.length;j++){      
                    for(var i=0;i<this.days.length;i++){
                        if(this.events[j].start==this.days[i]){
                            await addEventToShare(this.confirmedMail,this.$store.state.userMail,this.events[j]); 
                            recipesList.push(this.events[j].title);        
                        }
                    }                        
                }                 
                //elimina els repetits de l'array de noms
                var finalRecipesList = [];
                recipesList.forEach( (element) => {
                    if (!finalRecipesList.includes(element)) {
                        finalRecipesList.push(element);
                    }
                });
                //afegeix les receptes a les receptes per compartir i crea un array d'ingredients
                var ingredientsList = [];
                for(var k=0;k<finalRecipesList.length;k++){
                    var recipe= await getRecipe(this.$store.state.admin,this.$store.state.userUid,finalRecipesList[k]);                                           
                    for(var l=0;l<recipe.ingredients.length;l++){          
                        ingredientsList.push(recipe.ingredients[l].name);          
                    }                                   
                    await addRecipeToShare(this.confirmedMail,this.$store.state.userMail,recipe);                    
                } 
                //elimina els repetits de l'array d'ingredients
                var finalIngredientsList = [];
                ingredientsList.forEach( (element) => {
                    if (!finalIngredientsList.includes(element)) {
                        finalIngredientsList.push(element);
                    }
                });
                //afegeix els ingradients als ingredients per compartir
                for(var m=0;m<finalIngredientsList.length;m++){                   
                    var ingredient= await getIngredient(this.$store.state.admin,this.$store.state.userUid,finalIngredientsList[m]);                                                                                        
                    await addIngredientToShare(this.confirmedMail,this.$store.state.userMail,ingredient);                    
                }
                this.confirmedMail=""; 
                this.isLoading=false;
                swal("Llestos!", "La teva dieta s'ha compartit correctament", "success");                                                                          
            }                            
        }, 
    },
  async created(){
    this.events=await getAllEvents(this.$store.state.userUid);    
  },
}
</script>

<style scoped lang="scss">
.row{
    margin-top: 80px;
}
</style>