<template>
  <header id="header" class="header sticky">
    <div class="header-container container">
      <nav class="gap-5">
        <router-link to="/" class="btn-icon logo-btn">
          <img
            class="optimize logo logo-normal"
            src="../assets/logo_SVG.svg"
            alt="Logo nyami"
            width="694"
            height="286"
          />
          <img
            class="optimize logo logo-white"
            src="../assets/logo_white_SVG.svg"
            alt="Logo nyami"
            width="694"
            height="286"
          />
        </router-link>

        <ul class="menu-links">
          <li><router-link to="/aboutUs">Sobre nosaltres</router-link></li>
          <li><router-link to="/advices">Consells de l'Ona i la Vinyet</router-link></li>
          <li><router-link to="/contact">Contactar</router-link></li>          
        </ul>
      </nav>

      <router-link
        v-if="!isLoggedIn"
        to="/Login"        
        class="btn btn-login btn-light"
        title="Iniciar sesión"
        >Iniciar sessió</router-link
      >      

      <div class="d-flex align-items-center gap-3" v-if="isLoggedIn">               
        <p class="d-none d-sm-block mb-0">{{ currentUser }}</p>        
        <button class="d-none d-sm-block btn btn-light" @click="logout">
          Tancar sessió
        </button>
      </div>

      <div id="menuIcon" class="menu-icon">
        <div id="menuIconBar" class="menu-icon-bar"></div>
      </div>
    </div>
  </header>

  <div class="menu" id="menu">
    <nav id="navMobile" class="menu-nav-mobile">
      <div id="menuLinks">
        <ul class="menu-links" >
        <li><router-link to="/aboutUs">Sobre nosaltres</router-link></li>
          <li ><router-link to="/advices">Consells de l'Ona i la Vinyet</router-link></li>
          <li><router-link to="/contact">Contactar</router-link></li>
      </ul>
      <router-link
        v-if="isLoggedIn == false"
        to="/Login"      
        class="btn-login btn btn-light"
        title="Iniciar sesión"
        >Iniciar sessió</router-link
      >
      <button v-if="isLoggedIn" class="btn btn-light" @click="logout">
        Tancar sessió
      </button>
      </div>
      
    </nav>
  </div>
</template>

<script>
import { getAuth,  onAuthStateChanged,   signOut } from "firebase/auth";
import { isAdmin } from "@/db";
import { wantInformation } from "@/db";
import swal from 'sweetalert';

export default {
  name: "Header",
  data: function () {
    return {
      isLoggedIn: false,
      currentUser: "",
    };
  },
  async created() {    
    
    //Comprova si algun usuari ha iniciat la sessió
    const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
      if (user) {
        this.actualizeSignedIn(user);        
              
      } else {        
        this.isLoggedIn = false;
        this.$store.commit('setIsLoggedIn',false);
        this.$store.commit('setUserUid',"");
        this.$store.commit('setUserMail',"");
        this.$store.commit('setAdmin',"");
        this.$store.commit('setShowInformation',false);
        this.currentUser = "";
      }      
    });  
  },

  methods: {
    async actualizeSignedIn(user){
      this.isLoggedIn = true;
        this.$store.commit('setIsLoggedIn',true);        
        this.$store.commit('setUserUid',user.uid);        
        this.$store.commit('setUserMail',user.email);        
        const admin=await isAdmin(user.uid);
        this.$store.commit('setAdmin',admin);        
        const information=await wantInformation(user.uid);
        this.$store.commit('setShowInformation',information);         
        this.currentUser = user.email;        
    },
    //tancar la sessió
    logout: async function (e) {
      var confirmation=await swal({        
        text: "Confirmes que vols tancar la sessió?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      if(confirmation){
        try{
          const auth = getAuth();
          signOut(auth)
        } catch(error){
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
        };
        e.preventDefault();
        this.$router.push("/"); 
      }       
    },
  },

  mounted() {
    // MENU MOBILE
    const menuIconBar = document.getElementById("menuIconBar");
    const menuIcon = document.getElementById("menuIcon");
    const menuLinks = document.getElementById("menuLinks");
    const menu = document.querySelector("#menu");

    function toggleClassActive() {
      if (
        !(
          menuIconBar.classList.contains("menu-icon-cross") ||
          menuIconBar.classList.contains("menu-icon-hamburguer")
        )
      ) {
        menuIconBar.classList.add("menu-icon-cross");
      } else if (menuIconBar.classList.contains("menu-icon-cross")) {
        menuIconBar.classList.remove("menu-icon-cross");
        menuIconBar.classList.add("menu-icon-hamburguer");
      } else if (menuIconBar.classList.contains("menu-icon-hamburguer")) {
        menuIconBar.classList.remove("menu-icon-hamburguer");
        menuIconBar.classList.add("menu-icon-cross");
      }

      menu.classList.toggle("menu-visible");
    }

    menuIcon.addEventListener("click", toggleClassActive);
    menuLinks.addEventListener("click", toggleClassActive);
  },
};
</script>

<style scoped lang="scss">
.icon{
  width:35px
}
.header {
  color: white;
  width: 100%;
  z-index: 100;
  transition: all 0.4s ease;
  top: 0px;
  position: fixed;
}

.header .logo-btn {
  padding: 0;
}
.header .logo {
  height: 50px;
  width: auto;
  margin-right: 20px;
}
.header .logo.logo-normal {
  display: none;
}
.header.sticky {
  color: black;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(13, 10, 44, 0.04);  
}
.header.sticky .header-container .menu-links li a {
  color: black;
  
}
.header.sticky .logo.logo-normal {
  display: block;
}
.header.sticky .logo.logo-white {
  display: none;
}
.header .header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 70px;
}
.header .header-container nav {
  align-items: center;
  display: flex;
}
@media (max-width: 992px) {
  .header .header-container > .btn-login {
    display: none;
  }
}
.header .header-container .menu-links {
  display: flex;
}
.header .header-container .menu-links li {
  margin-right: 15px;
  font-weight: 500;
}
.header .header-container .menu-links li a {
  color: white;
  text-decoration: none;
}
.header .header-container .menu-links li a:hover {
  color: $primary;
  text-decoration: none;
}
@media (max-width: 992px) {
  .header .header-container .menu-links {
    display: none;
  }
}
.menu {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 100%;
  height: 100vh;
  width: 100vw;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 128px;
  background: $primary;
  transition: left 0.4s, background-color 0.2s;
}
.menu.menu-visible {
  left:0;
  background: $primary;
}
.menu .menu-nav-mobile {
  text-align: center;
}
.menu .menu-nav-mobile .menu-links a {
  display: block;
  text-align: center;
  padding: 7px 20px;
  text-decoration: none;
  color: white;
}
.menu .menu-nav-mobile .menu-links a:hover {
  background: white;
  color: $primary;
}
.menu .btn-login {
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}
.menu-icon {
  height: 40px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .menu-icon {
    display: none;
  }
}
.menu-icon-bar {
  position: relative;
  height: 4px;
  width: 24px;
  border-radius: 2px;
  background-color: white;  
}

.menu-icon-bar:before,
.menu-icon-bar:after {
  content: "";
  position: absolute;
  left: 0;
  height: 4px;
  width: 24px;
  border-radius: 2px;
  background-color: white;
  transform-origin: center center;
}

.header.sticky .menu-icon-bar,
.header.sticky .menu-icon-bar:before,
.header.sticky .menu-icon-bar:after{
  background-color: $primary;
}

.menu-icon-bar:before {
  top: -7px;
}
.menu-icon-bar:after {
  top: 7px;
}
.menu-icon-bar:hover {
  cursor: pointer;
}
.menu-icon-cross {
  animation: originalToCross 0.4s forwards;
}
.menu-icon-cross:before {
  animation: beforeToCross 0.4s forwards;
}
.menu-icon-cross:after {
  animation: afterToCross 0.4s forwards;
}
.menu-icon-hamburguer {
  animation: originalToHamburguer 0.4s forwards;
}

.header.sticky .menu-icon-hamburguer {
  animation: stickyOriginalToHamburguer 0.4s forwards;
}
.menu-icon-hamburguer:before {
  animation: beforeToHamburguer 0.4s forwards;
}
.menu-icon-hamburguer:after {
  animation: afterToHamburguer 0.4s forwards;
}
@keyframes originalToHamburguer {
  0% {
    background-color: transparent;
  }
  40%,
  100% {
    background-color: white; 
  }
}

@keyframes stickyOriginalToHamburguer {
  0% {
    background-color: transparent;
  }
  40%,
  100% {
    background-color: $primary; 
  }
}



@keyframes beforeToHamburguer {
  0% {
    top: 0;
    transform: rotate(45deg);
  }
  20%,
  40% {
    top: 0;
    transform: rotate(0);
  }
  100% {
    top: -6px;
  }
}
@keyframes afterToHamburguer {
  0% {
    top: 0;
    transform: rotate(-45deg);
  }
  20%,
  40% {
    top: 0;
    transform: rotate(0);
  }
  100% {
    top: 6px;
  }
}
@keyframes originalToCross {
  0% {
    background-color: white;
  }
  40%,
  100% {
    background-color: transparent;
  }
}
@keyframes beforeToCross {
  0% {
    top: -6px;
  }
  20%,
  40% {
    top: 0;
    transform: rotate(0);
  }
  100% {
    top: 0;
    transform: rotate(45deg);
  }
}
@keyframes afterToCross {
  0% {
    top: 6px;
  }
  20%,
  40% {
    top: 0;
    transform: rotate(0);
  }
  100% {
    top: 0;
    transform: rotate(-45deg);
  }
}


</style>
