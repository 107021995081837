<template>
  <div class="app-container text-center">
    <main class="page-home">      
      <section class="block-list">
        <div class="container">
          <h1>RECEPTES DISPONIBLES</h1>          
          <div class="row filter">
            <div class="col-md-12">
              <p>Filtrar receptes</p>
            </div>            
            <div class="form-group col-md-6">
              <label class="form-label" for="searchValue">Títol de la recepta</label>
              <input class="form-control" type="text" v-model="searchValue" placeholder="Recepta" id="search-input"/>
            </div>                 
          <div class="form-group col-md-6">
            <label class="form-label" for="category">Categoria</label>
            <select class="form-select" v-model="category" >                          
              <option value="Altres">Altres</option>
              <option value="Arròs">Arròs</option>
              <option value="Berenar">Berenar</option>
              <option value="Carn">Carn</option>
              <option value="Crema o puré">Crema o puré</option>
              <option value="Esmorzar">Esmorzar</option>
              <option value="Gra">Gra</option>
              <option value="Pasta o pizza">Pasta o pizza</option>
              <option value="Peix">Peix</option>
              <option value="Postres">Postres</option>
              <option value="Sopa">Sopa</option>
              <option value="Verdura">Verdura</option>
              <option value="">Totes</option>
            </select>
          </div>
          </div>
          
          <p class="mt-3">Si no trobes la recepta que busques també la pots crear</p>
          <router-link to="/newRecipe"><button class="btn btn-inverse">Crear recepta</button></router-link>
          <div class="row mt-3">
            <div
              class="col-md-6 col-lg-4"
              v-for="recipeCard in filteredRecipes"
              v-bind:key="recipeCard.name"
            >              
              <RecipeCard @deleteThisRecipe="deleteThisRecipe" v-bind:recipeDate="recipeDate" v-bind:recipeCard="recipeCard" ></RecipeCard> 
            </div>
          </div>
        </div>
      </section>      
    </main>
  </div>
</template>

<script>
import { getAllRecipes } from "@/db";
import RecipeCard from "@/components/RecipeCard.vue";
import { deleteRecipe } from "@/db";


export default {
  name: "Recipes",
  data() {
    return {
      recipes: [],
      searchValue:"",
      category:"",
      recipeDate:"",           
    };
  },

  components: {    
    RecipeCard
  },
  
  methods:{
    async deleteThisRecipe(name){
      await deleteRecipe(this.$store.state.admin,this.$store.state.userUid,name);      
      this.recipes = await getAllRecipes(this.$store.state.admin,this.$store.state.userUid);
      this.searchValue="";
    }
  }, 
  
  computed: {
        filteredRecipes() {
            let filRec = this.recipes;            
            if (this.searchValue != "") {
                filRec = filRec.filter((item) => {
                    return item.name.toUpperCase().includes(this.searchValue.toUpperCase())
                })
            }
            if (this.category != "") {
                filRec = filRec.filter((item) => {                    
                    return item.category==this.category                    
                })
                
            }                     
            return filRec;
        }
    },

  async created() {    
    this.recipes = await getAllRecipes(this.$store.state.admin,this.$store.state.userUid);
    this.recipeDate = this.$route.params.date;          
  },
  
};
</script>

<style scoped lang="scss">
// Estils
.filter{    
    border: rgba(128, 128, 128, 0.397) 1px solid;
    border-radius: 10px;    
    padding: 10px;
}

h1{
  margin-top: 80px;
}
</style>
