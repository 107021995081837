<template>
    <main class=" container mt-5">
        <form v-on:submit.prevent="createRecipe">
        <div class="row mt-4 d-flex align-items-center text-center">
            <div class="col-12">
                <h1 class="main-title">NOVA RECEPTA</h1>
                <p class="auth-intro">Apunta el què vulguis però pensa que ho hauràs de cuinar!</p>
                <p class="comment">(Els camps marcats amb <span>*</span> són obligatoris)</p>
            </div>
            <div class="col-md-6 col-lg-4">                
                <div class="form-group mb-3">
                    <label class="form-label" for="recipe.name">Nom <span>*</span></label>
                    <input
                        v-model="recipe.name"
                        placeholder="Nom"
                        type="text"
                        id="recipe.name"
                        class="form-control"
                        required
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-label" for="recipe.description">Descripció</label>
                    <textarea
                        v-model="recipe.description"
                        placeholder="4 cullerades de com anirà"
                        name="recipe.description"
                        id="recipe.description"
                        cols="30"
                        rows="5"
                        class="form-control"            
                    />
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="form-group mb-3">   
                    <label class="form-label" for="myFile">Seleccionar imatge</label>
                    <input
                        v-on:change="putMyFile"
                        type="file"
                        id="myFile"
                        class="form-control"                
                    />
                    <img
                        class="mt-3"
                        v-if="myFile != null"
                        :src="myFileURL"
                        alt=""
                        height="200"
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-label" for="image_url">O enganxa la Url d'una imatge 
                        <button  @click.prevent="clarificationMobile" class="btn btn-sm btn-inverse mobile">
                        ?
                        </button>
                        <button  @click.prevent="clarificationDesktop" class="btn btn-sm btn-inverse desktop">
                        ?
                        </button>
                    </label>
                    <input
                        v-model="recipe.image_url"
                        placeholder="Url de la imatge"
                        type="text"
                        id="image_url"
                        class="form-control"              
                    />
                    <img
                        class="mt-3"
                        v-if="recipe.image_url != ''"
                        :src="recipe.image_url"
                        alt=""
                        height="200"
                    />
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="form-group mb-3">
                    <label class="form-label" for="recipe.thermomix">És una recepta de Thermomix? <span>*</span></label>
                    <div class="d-flex justify-content-center gap-2">
                        <p>
                            <input
                                v-model="recipe.thermomix"            
                                type="radio"
                                id="thermomix"
                                class="form-check-input"
                                name="thermomix"
                                value="Sí"
                                required
                            />
                            Sí
                        </p>
                        <p>
                            <input
                                v-model="recipe.thermomix"            
                                type="radio"
                                id="no_thermomix"
                                class="form-check-input"
                                name="thermomix"
                                value="No"
                                required
                            />
                            No
                        </p>                
                    </div>  
                </div>
                <div class="form-group mb-3">
                    <label class="form-label" for="recipe.recipeLink">Enllaç a la recepta</label>
                    <input
                        v-model="recipe.recipeLink"
                        placeholder="On ens explicaran com ho hem de fer?"
                        type="text"
                        id="recipeLink"
                        class="form-control"            
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-label" for="recipe.rations">Racions <span>*</span></label>
                    <input
                        v-model="recipe.rations"
                        placeholder="Quants en menjarem?"
                        type="number"
                        id="rations"
                        class="form-control" 
                        required   
                        min="1"        
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-label" for="recipe.category">Categoria <span>*</span></label>
                    <select class="form-select" name="recipe.category" id="recipe.category" v-model="recipe.category" required>                          
                        <option value="Altres">Altres</option>
                        <option value="Arròs">Arròs</option>
                        <option value="Berenar">Berenar</option>
                        <option value="Carn">Carn</option>
                        <option value="Crema o puré">Crema o puré</option>
                        <option value="Esmorzar">Esmorzar</option>
                        <option value="Gra">Gra</option>
                        <option value="Pasta o pizza">Pasta o pizza</option>
                        <option value="Peix">Peix</option>
                        <option value="Postres">Postres</option>
                        <option value="Sopa">Sopa</option>
                        <option value="Verdura">Verdura</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="form-group">
                    <label class="form-label" for="ingredients">Ingredients</label>
                    <div>                  
                        <v-select :options="ingredientsNames" v-model="actualIngredient.name"/> 
                        <form v-on:submit.prevent="addIngredientInArray">
                            <div class="mt-2 d-flex align-items-center justify-content-center gap-2" v-if="actualIngredient.name!=null">        
                                {{actualIngredient.name}}
                                <input  v-model="actualIngredient.quantity" type="number" class="input-quantity form-control" required min="0" step="any" >
                                <select class="form-select" name="unit_mesure" id="unit_mesure" v-model="actualIngredient.mesure_unit" required >                                                              
                                        <option v-if="actualIngredient.quantity==1" :value="filteredIngredient.singular_mesure_unit">{{filteredIngredient.singular_mesure_unit}}</option>
                                        <option v-if="actualIngredient.quantity!=1" :value="filteredIngredient.mesure_unit">{{filteredIngredient.mesure_unit}}</option>
                                        <option v-if="actualIngredient.quantity==1" :value="filteredIngredient.singular_alternative_mesure">{{filteredIngredient.singular_alternative_mesure}}</option>
                                        <option v-if="actualIngredient.quantity!=1" :value="filteredIngredient.alternative_mesure">{{filteredIngredient.alternative_mesure}}</option>                       
                                </select>                                
                                <button class="btn btn-inverse btn-sm" type="submit">Afegir</button>                                
                            </div>
                        </form>                                
                        <ul>
                            <li class="mt-2" v-for="(item, index) in recipe.ingredients" :key="item.name">
                                {{ item.name }} {{item.quantity}} {{item.mesure_unit}}<button class="mx-2 btn btn-danger btn-sm " @click="removeIngredient(index)">x</button>
                            </li>
                        </ul>                              
                    </div>
                </div>
            </div> 
            <div class="col-md-6 col-lg-4">
                <p class="mt-3">Si algun ingredient no el trobes al buscador, el pots afegir <button @click.prevent="toggleNewIngredientView" class="btn btn-inverse btn-sm">+</button></p>                    
                <div v-if="newIngredientView" class="url d-flex justify-content-center mb-3">
                    <form class="mt-2" v-on:submit.prevent="addNewIngredient">
                        <div class="form-group">
                            <label class="form-label" for="name">Nom de l'ingredient</label>                         
                            <input
                                v-model="newIngredient.name"
                                type="text"
                                class="form-control"
                                required
                            />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="mesure_unit">Unitat de mesura</label>
                            <v-select :options="mesureUnits" v-model="newIngredient.mesure_unit"/>                                                  
                        </div>                
                        <div class="form-group">
                            <label class="form-label" for="alternative_mesure">Unitat de mesura alternativa</label>                         
                            <v-select :options="mesureUnits" v-model="newIngredient.alternative_mesure"/>                              
                        </div>                
                        <button type="submit" class="mt-2 btn btn-inverse btn-md">Crear</button>
                    </form>
                </div>      
            </div> 
            <div class="col-md-6 col-lg-4">
                <button type="submit" class="btn btn-inverse btn-md">Registrar</button>
            </div>                    
        </div>
        </form>
    </main>
</template>

<script>

import { getAllIngredients } from "@/db";
import { addIngredient } from "@/db";
import { addRecipe } from "@/db";
import { getRecipe } from "@/db";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import swal from 'sweetalert';



export default {
    name: "newRecipe",
    data: function () {
        return {
            mesureUnits:["ampolles", "bricks", "culleradetes", "grams" , "litres", "paquets", "pessics", "pots", "rajoles", "rodanxes", "talls", "tires", "unitats"],
            ingredients: [],            
            ingredientsNames: [],                                
            recipe:{
                name:"",
                description:"",
                image_url:"",
                thermomix:null,
                recipeLink:"",
                rations:null,
                category:null,
                ingredients:[]
            },        
            actualIngredient: {
                name:null,
                mesure_unit:null,
                quantity:null
            },
            
            myFile: null,
            myFileURL: "",
            fileURL: "",
            newIngredientView:false,
            newIngredient:{
                name:"",
                mesure_unit:"",
                singular_mesure_unit:"",
                alternative_mesure:"",
                singular_alternative_mesure:""
            }
    
        };
    },
    
    async created() {    
        this.ingredients = await getAllIngredients(this.$store.state.admin,this.$store.state.userUid);                                
        this.ingredients.forEach((element) => {
            this.ingredientsNames.push(element.name);
        });
    },        

    computed: {        
        filteredIngredient() {
            let filIng = this.ingredients              
            if (this.actualIngredient.name != null) {
                filIng = filIng.filter((item) => {
                    return item.name.includes(this.actualIngredient.name)
                })
            }                     
            return filIng[0]
        }        
    },

    methods: {
        clarificationDesktop(){            
            swal({text:"Busca una imatge en un cercador, fes click amb el botó de la dreta i a 'copiar direcció de la imatge', després enganxa l'adreça a l'espai indicat", icon:"info"});
        },
        clarificationMobile(){            
            swal({title:"La cosa és complicada", text:"Busca una imatge en un cercador, prem a 'visita' per entrar a la pàgina on hi ha la foto, prem de forma sostinguda sobre la imatge, prem per obrir la imatge en una pestanya nova, prem sobre l'adreça del navgeador i copia-la, finalment enganxa-la al lloc indicat del formaulari", icon:"info"});
        },         
        async createRecipe(){
            var actualRecipe= await getRecipe(this.$store.state.admin,this.$store.state.userUid,this.recipe.name);
            if(actualRecipe==null){
                if (this.myFile != null) {
                    this.recipe.image_url = await this.uploadFile();
                } 
                await addRecipe(this.$store.state.admin,this.$store.state.userUid,this.recipe);
                swal("Bona feina!", "La recepta s'ha creat correctament i ja la pots afegir al calendari", "success");
                this.$router.push("/");
            }else{
                swal("Ja existeix!", "Ja hi ha una recepta amb aquesta nom", "error");
            }
                                                                         
        },          

        //afegeix un ingredient a l'array d'ingredients
        addIngredientInArray() {                   
            this.recipe.ingredients.push(this.actualIngredient);
            this.actualIngredient={};
            this.actualIngredient.name=null;
            this.actualIngredient.mesure_unit=null;
            this.actualIngredient.quantity=null;
            this.searchValue="";      
        },

        //borra un ingredient de l'array
        removeIngredient(index) {
            this.recipe.ingredients.splice(index, 1);
        },

        //carrega l'arxiu i en guarda la URL local
        putMyFile(event) {
            this.myFile = event.target.files[0];
            this.myFileURL = URL.createObjectURL(this.myFile);            
        },

        //carrega una imatge a Storage i en reotorna la URL
        async uploadFile() {
            const storage = getStorage();
            const storageRef = ref(storage, "images/" + this.myFile.name);
            await uploadBytes(storageRef, this.myFile);
            const fileUrl = await getDownloadURL(storageRef);            
            return fileUrl;
        },

        toggleNewIngredientView() {
            this.newIngredientView = !this.newIngredientView;
        },

        async addNewIngredient() {  
            //posa la primera lletra del nom de l'ingredient en majúscula            
            this.newIngredient.name=this.newIngredient.name.charAt(0).toUpperCase()+this.newIngredient.name.slice(1);
            //posa els noms en singular de les unitats de mesura  
            var singularMesureUnits=["ampolla", "brick", "culleradeta", "gram" , "litre", "paquet", "pessic", "pot", "rajola", "rodanxa", "tall", "tira", "unitat"];
            for(var i=0;i<this.mesureUnits.length;i++){
                if(this.mesureUnits[i]==this.newIngredient.mesure_unit){
                    this.newIngredient.singular_mesure_unit=singularMesureUnits[i];
                }
                if(this.mesureUnits[i]==this.newIngredient.alternative_mesure){
                    this.newIngredient.singular_alternative_mesure=singularMesureUnits[i];
                }
            }             
            await addIngredient(this.$store.state.admin,this.$store.state.userUid,this.newIngredient);                          
            swal("Ben fet!", "L'ingredient "+this.newIngredient.name+" s'ha afegit a la base de dades", "success");
            var ingredientName= this.newIngredient.name; 
            this.newIngredient={};
            this.newIngredient.name="";
            this.newIngredient.mesure_unit="";
            this.newIngredient.singular_mesure_unit="";             
            this.newIngredient.alternative_mesure="";
            this.newIngredient.singular_alternative_mesure=""; 
            this.ingredients=[];
            this.ingredientsNames=[];            
            this.ingredients = await getAllIngredients(this.$store.state.admin,this.$store.state.userUid);
            this.ingredients.forEach((element) => {
                this.ingredientsNames.push(element.name);
            });   
            this.actualIngredient.name=ingredientName;
                             
        },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 668px) { .mobile { display: none } }
@media (max-width: 668px) { .desktop { display: none } }

.input-quantity{
    width: 120px;
}

.form-select, .form-control{
    font-size: 14px;    
    padding-left: 5px;
}

.comment{
    font-size:10px;    
  }

span{
    color:$primary;
    font-size:15px;
}

.url{    
    border: rgba(128, 128, 128, 0.397) 1px solid;
    border-radius: 10px;    
    padding: 10px;
}
</style>
