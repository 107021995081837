<template>
  <footer>
    <div class="container mt-5 mb-5 d-flex justify-content-between">
      <span>©nyami {{ actual_year }}</span>

      <ul class="legal-links d-flex gap-4">
        <li><router-link to="/legalWarning">Avís legal</router-link></li>        
        <li><router-link to="/faq">FAQ</router-link></li>        
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    //any actual
    actual_year() {
      var d = new Date();
      return d.getFullYear();
    }
  }  
};
</script>

<style scoped lang="scss">
footer {
  background:white ;
  color: $primary;
  padding: 20px 0;
  margin-top: auto;
  font-size: 14px;
  font-weight: 500;

  a {
    color: black;
    &:hover {
      color: $primary;
    }
    text-decoration: none;
  }

}
</style>
