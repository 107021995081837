<template>
    <div class="vld-parent">
        <loading v-model:active="isLoading"/>         
    </div>
    <div >  
      <div  class="botom-navegation d-flex justify-content-center gap-4 fixed-bottom">
        <button aria-label="day" @click="activeView = 'day'" :class="{active:activeView=='day'}" class="botom-btn"><img src="../assets/icons/icon_day.svg" height ="50" width="50" alt="icono dia"  /></button>
        <button aria-label="month" @click="activeView = 'month'" :class="{active:activeView=='month'}" class="botom-btn"><img src="../assets/icons/icon_month.svg" height ="50" width="50" alt="icono mes"/></button>
        
          <router-link to="/shoppingList">
            <button class="botom-btn" aria-label="shopping list">
              <img src="../assets/icons/icon_shopping.svg" height ="50" width="50" alt="icono carret" />
            </button>
          </router-link>
          <router-link to="/share">
            <button class="botom-btn" aria-label="share">
              <img src="../assets/icons/icon_share.svg" height ="50" width="50" alt="icono compartir"/>
            </button>
          </router-link>
                
      </div>    
      
      <vue-cal             
           
            :time="false"  
            style="height: 700px"
            :disable-views="['years', 'year', 'week']"
            hide-view-selector
            today-button
            :editable-events="{ title: false, drag: true, resize: false, delete: true, create: false }"
            :events="events"            
            locale="ca"
            :active-view="activeView"
            
            events-on-month-view="true"
            @cell-dblclick="addRecipe"
            @event-drop="copyEventInOtherDay"
            @event-delete="deleteThisEvent"
            :onEventClick="viewRecipe"
            class="vuecal--full-height-delete"
      >
            <template v-slot:title="{ title }">
              <button class="calendarTitle" disabled>{{title}}</button>              
              <button v-if="activeView=='month'" @click="clarificationMobile" class="mx-3 btn btn-sm btn-inverse clarification mobile">?</button>
              <button v-if="activeView=='month'" @click="clarificationDesktop" class="mx-3 btn btn-sm btn-inverse clarification desktop">?</button> 
            </template>
      </vue-cal>
      
        
        
    </div>
    
    
    
</template>
<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import 'vue-cal/dist/i18n/ca.js'
import 'vue-cal/dist/drag-and-drop.js' //drag, arrossegar events
import { addEvent } from "@/db"; //crear event
import { getAllEvents } from "@/db";
import { sharedThings } from "@/db";
import { peopleWhoShare } from "@/db";
import { actualizeSharedThings } from "@/db";
import { deleteEvent } from "@/db";
import swal from 'sweetalert';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';



export default {
  components: { 
    VueCal,
    Loading
  },
  data() {
    return {   
      isLoading:false,   
      events: [],
      activeView:null      
    };
  },
  props:{
    view:String
  },
  async created(){
    
    if(this.view==null){
      this.activeView="month";
    }else{
      this.activeView=this.view;
    }
    //Comprovar si té elements compartits
    if(await sharedThings(this.$store.state.userMail)){
      var mails= await peopleWhoShare(this.$store.state.userMail);
      for(var i=0;i<mails.length;i++){
        var acceptShare= await swal({
          title: "Mira això!",
          text: mails[i]+" ha compartit la seva dieta amb tu. Acceptes rebre-la?",
          icon: "warning",
          buttons: true,          
        })
        if(acceptShare){  
          this.isLoading=true;                  
          await actualizeSharedThings(this.$store.state.userMail,mails[i],this.$store.state.userUid,this.$store.state.admin);
          this.isLoading=false;  
          swal("Els àpats s'han incorporat al teu calendari", {icon: "success",});
        }else{
          swal("S'han eliminat la dieta compartida", {icon: "error",});
        }
      }                
    }
    this.isLoading=true;  
    this.events=await getAllEvents(this.$store.state.userUid);
    this.isLoading=false;  
    
  },
  methods:{
      clarificationMobile(){                                        
        swal({       
          icon:'https://firebasestorage.googleapis.com/v0/b/nyami-c7d89.appspot.com/o/images%2Fcolors3.png?alt=media&token=d5004200-3771-4796-9146-92b512c063c2',
          text: "En la versió per ordinador es poden borrar i moure elements del calendari"
        });
      }, 
      clarificationDesktop(){                                        
        swal({       
          icon:'https://firebasestorage.googleapis.com/v0/b/nyami-c7d89.appspot.com/o/images%2Fcolors3.png?alt=media&token=d5004200-3771-4796-9146-92b512c063c2',
          text: "Prement de forma sostinguda un element apareix l'opció de borrar-lo. \n\nSi s'arrossega una element cap a un altre dia aquest es copia en la nova data."
        });
      }, 
      //ha de passar el títol de la recepta com a paràmetre
      viewRecipe(event){          
          this.$router.push("/actualRecipe/"+event.title);
      },
      addRecipe(event){        
        this.$router.push("/recipes/"+event.format());        
      },
      async copyEventInOtherDay(event){
        var newEvent={
          title: event.originalEvent.title,
          start: event.newDate.format(),
          end: event.newDate.format(),
          class: event.originalEvent.class
        };        
        await addEvent(this.$store.state.userUid,newEvent);
        this.events=await getAllEvents(this.$store.state.userUid);
      },
      async deleteThisEvent(event){        
        var title=event.title;
        var moment=event.class;
        var start= event.start.format();        
        await deleteEvent(this.$store.state.userUid,title,moment,start);        
        swal("L'element s'ha borrat del calendari", {icon: "success",});
        this.events=await getAllEvents(this.$store.state.userUid);
      }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 668px) { .mobile { display: none } }
@media (max-width: 668px) { .desktop { display: none } }

.calendarTitle{
  color:black;  
  cursor:initial;  
}

.clarification {    
    z-index: 9;
    border-color: white;
    color: white ;
    background: $primary;

    &:hover{
        border-color: $primary;
        background-color: white;
        color: $primary;
    }
  }

</style>
