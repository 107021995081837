<template>  
  <article class="card d-flex flex-column justify-content-between">
    <div>
      <div class="card-header">
        <router-link to="/">
          <button v-if="recipeDate==null"  type="button" class="close-card btn-close" aria-label="Close"></button>
        </router-link>        
        <img
          class="img-fluid"
          v-bind:src="recipeCard.image_url"
          :alt="recipeCard.name"
        />
      </div>
      <div class="mt-2">      
        <h2 class="card-title">{{ recipeCard.name }}</h2>
        <p class="px-2">{{recipeCard.description}}</p>                                                                                                            
      </div>
    </div>
    
    <div>
      <div class="d-flex justify-content-center align-items-center gap-4 mt-4 mb-2">
        <img v-if="recipeCard.thermomix=='Sí'" src="../assets/icons/icon_thermomix.png" alt="icon thermomix" />
        <div class="d-flex gap-1 align-items-center">
          <img class="icon" src="../assets/icons/icon_users.svg" alt="icon rations"/>
          <span class="rations">{{recipeCard.rations}}</span>
        </div>
        
      </div>      
      <div class="d-flex justify-content-center gap-2 mt-4 mb-2">
        <button v-if="recipeCard.ingredients!=0" @click="toggleIngredientsView" class="btn btn-inverse">Ingredients <span v-if="!ingredientsView">+</span><span v-else>-</span></button>
        <a v-if="recipeCard.recipeLink!=''" :href="recipeCard.recipeLink" target="_blank">
          <button class="btn btn-inverse">Link</button>
        </a>
        <button v-if="recipeDate!=null" @click="addToCalendar" class="btn btn-light">Afegir</button>
        <button v-if="recipeDate!=null" @click="updateActualRecipe" class="delete" >
          <img class="deleteNormal icon" src="../assets/icons/icon_modify.svg" alt="icon delete" />
          <img class="deleteHover icon" src="../assets/icons/icon_modify_hover.svg" alt="icon delete" />
        </button>
        <button v-if="recipeDate!=null" @click="deleteActualRecipe" class="delete" >
          <img class="deleteNormal icon" src="../assets/icons/icon_delete.svg" alt="icon delete" />
          <img class="deleteHover icon" src="../assets/icons/icon_delete_hover.svg" alt="icon delete" />
        </button>
                          
      </div>
      <div v-if="ingredientsView" class="mt-4 mb-2">
        <ul>
          <li v-for="ingredient in recipeCard.ingredients" :key="ingredient">
            {{ingredient.name}}: {{ingredient.quantity}} {{ingredient.mesure_unit}}
          </li>
        </ul>
      </div> 
    </div>
  </article>
  
</template>

<script>
import { addEvent } from "@/db";
import swal from 'sweetalert';

export default {
  name: "RecipeCard",
  data() {
    return {
      meal:"",
      ingredientsView: false     
    };
  },
  emits:["deletedThisRecipe"],
  props: {
    recipeCard: Object,
    recipeDate: String,    
  },     
  methods: {
    updateActualRecipe(){
      this.$router.push("/modifyRecipe/"+this.recipeCard.name);
    },
    async deleteActualRecipe(){              
      swal({
        title: "N'estàs segur?",
        text: "Un cop borrada no la podràs recuperar!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          this.$emit("deleteThisRecipe",this.recipeCard.name); 
          swal("La recepta s'ha borrat!", {
            icon: "success",
          });
        } 
      })           
    },
    //canvia la visualització dels ingredients de la recepta
    toggleIngredientsView() {
      this.ingredientsView = !this.ingredientsView;
    },
    async addToCalendar(){
      var whatMeal= await swal({        
        text: "Per quin àpat serà la recepta?",
        icon: "warning",        
        buttons: {          
          esmorzar: {
            text: "Esmorzar",
            value: "esmorzar",
          },
          dinar: {
            text: "Dinar",
            value: "dinar",
          },
          berenar: {
            text: "Berenar",
            value: "berenar",
          }, 
          sopar: {
            text: "Sopar",
            value: "sopar",
          },
          
        }       
      })
      this.meal=whatMeal;
      
      if(!this.meal==""){
        var newEvent={
          title: this.recipeCard.name,
          start: this.recipeDate,
          end: this.recipeDate,
          class: this.meal
        };
        
        await addEvent(this.$store.state.userUid,newEvent);
        this.$router.push("/");
      }    
    }
  },
};
</script>

<style scoped lang="scss">
.rations{
  color: $primary;
}

.icon{
  width: 25px;  
}

.delete{
  border: none;
  background: none;
  padding: 0;
}
.delete{
  .deleteNormal{
    display: block;
  }
  .deleteHover{
    display: none;
  }
}
.delete:hover{
  .deleteNormal{
    display: none;
  }
  .deleteHover{
    display: block;
  }
}

.close-card{
  z-index: 10;
  position:absolute;
  top:15px;
  right:15px;
  background-color: $primary;
}

p{
  font-size: 14px;
}
.card {
  margin-bottom: 15px;  
  height: calc(100% - 15px);

  .card-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .card-text {
    font-size: 14px;
    color: rgb(95, 95, 95);
  }

  .card-header {
    padding: 0 0 0 0;
    
    display: flex;
    
    justify-content: center;
    overflow: hidden;
  }   

  &:hover {    
    .card-header img {
      transform: scale(1.1);
      transition: transform 0.7s, filter 0.3s, -webkit-transform 0.7s,
        -webkit-filter 0.3s;
    }
    .card-body {
      background: #f9f9f9;
    }
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
}

</style>