<template>  
    <div class="container d-flex justify-content-center mt-5">
        <div class="col-md-6 mt-5">            
            <h1 class="main-title text-center">PREGUNTES FREQÜENTS</h1>           
            <div class="mb-3">                
                <h2>
                    <button @click="toggleAnswerView(0)" class="btn btn-inverse btn-sm">
                        <span v-if="!answerView[0]">+</span>
                        <span v-else>-</span></button>
                         Com s'introdueix un àpat al calendari?
                </h2>
                <p v-if="answerView[0]">Des de la vista diària o la vista de mes s'ha de premer dues vegades per accedir al menú de selecció de receptes. Allà es pot seleccionar una recepta o entrar al menú de crear una recepta.</p>
            </div>
            <div class="mb-3">                
                <h2>
                    <button @click="toggleAnswerView(1)" class="btn btn-inverse btn-sm">
                        <span v-if="!answerView[1]">+</span>
                        <span v-else>-</span></button>
                         Per veure una recepta ja introduïda al calendari he de buscar-la al cercador de receptes?
                </h2>
                <p v-if="answerView[1]">No. Prement una vegada sobre un àpat del calendari, ja sigui en visió diària o mensual, s'entra a la recepta en qúestió.</p>
            </div> 
            <div class="mb-3">                
                <h2>
                    <button @click="toggleAnswerView(2)" class="btn btn-inverse btn-sm">
                        <span v-if="!answerView[2]">+</span>
                        <span v-else>-</span></button>
                         Puc eliminar un àpat del calendari?
                </h2>
                <p v-if="answerView[2]">Sí, però només des de la versió per ordinador. S'ha de prémer de forma sostinguda sobre un àpat i apareix l'opció d'eliminiar-lo.</p>
            </div> 
            <div class="mb-3">                
                <h2>
                    <button @click="toggleAnswerView(3)" class="btn btn-inverse btn-sm">
                        <span v-if="!answerView[3]">+</span>
                        <span v-else>-</span></button>
                         Puc copiar un àpat del calendari d'un dia directament a un altre dia?
                </h2>
                <p v-if="answerView[3]">Sí, però només des de la versió per ordinador. S'ha de prémer i arrossegar l'àpat que es vol copiar fins al dia on es vol posar. S'ha de tenir en compte que es copiarà igual que en el dia on estava, o sigui, si era un dinar continuarà sent un dinar.</p>
            </div>
            <div class="mb-3">                
                <h2>
                    <button @click="toggleAnswerView(4)" class="btn btn-inverse btn-sm">
                        <span v-if="!answerView[4]">+</span>
                        <span v-else>-</span></button>
                         Puc copiar els àpats de més d'un dia o setmana a la vegada?
                </h2>
                <p v-if="answerView[4]">No. Actualment aquesta opció no està disponible</p>
            </div> 
            <div class="mb-3">                
                <h2>
                    <button @click="toggleAnswerView(5)" class="btn btn-inverse btn-sm">
                        <span v-if="!answerView[5]">+</span>
                        <span v-else>-</span></button>
                         Puc compartir receptes que un altre usuari no tingui registrades?
                </h2>
                <p v-if="answerView[5]">Sí. Quan es comparteix la dieta, es comprova que l'usuari receptor tingui totes les receptes i ingredients. Si no és així se li afegeixen.</p>
            </div>   
            <div class="mb-3">                
                <h2>
                    <button @click="toggleAnswerView(6)" class="btn btn-inverse btn-sm">
                        <span v-if="!answerView[6]">+</span>
                        <span v-else>-</span></button>
                         Com s'instal·la l'aplicació al mòbil?
                </h2>
                <ol v-if="answerView[6]">
                    <li>1. Obrir l'enllaç de la pàgina i obrir el menú desplegable tocant els tres punts de la part superior dreta de la pantalla.</li>
                    <img class="mx-auto my-2 d-block install" src="@/assets/install1_arrow.jpg" alt="">
                    <li>2. Tocar l'opció “Instal·la l'aplicació”</li>
                    <img class="mx-auto my-2 d-block install" src="@/assets/install2_arrow.jpg" alt="">
                    <li>3. Quan s'obre la finestra emergent tocar l'opció “Instal·la” i un cop acabat el procés obrir l'aplicació.</li>
                    <img class="mx-auto my-2 d-block install" src="@/assets/install3_arrow.jpg" alt="">                    
                </ol>
            </div>        
        </div>
           
    </div>
  
</template>

<script> 
        
export default {
  name: "contact",
  data: function () {
    return {
      answerView: [false,false,false,false,false,false,false] 
    };
  },
  methods: {
      toggleAnswerView(x) {
      this.answerView[x] = !this.answerView[x];
    },      
  }
};
</script>

<style scoped lang="scss">
h2{
    font-size: 16px;
}

.install{
    width:300px
}

</style>