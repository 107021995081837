<template >   
    
        <td v-if="show">{{ingredient.name}} </td>
        <td v-if="show"><input type="number" :value="finalIngredient.quantity" @change="actualizeQuantity" min="0" step="any" ></td>
        <td v-if="show">
          <select name="unit_mesure" id="unit_mesure"  v-model="finalIngredient.mesure_unit">            
            <option v-if="finalIngredient.quantity!=1" :value="mesure_unit">{{mesure_unit}}</option>
            <option v-if="finalIngredient.quantity==1" :value="singular_mesure_unit">{{singular_mesure_unit}}</option> 
            <option v-if="finalIngredient.quantity!=1" :value="alternative_mesure">{{alternative_mesure}}</option>
            <option v-if="finalIngredient.quantity==1" :value="singular_alternative_mesure">{{singular_alternative_mesure}}</option>                         
          </select>
        </td>        
        <td v-if="show">
          <select name="shop" id="shop"  v-model="finalIngredient.shopToBuy">
            <option v-for="shop in shops" v-bind:key="shop" :value="shop.name">{{shop.name}}</option>                                     
          </select>                                 
        </td>
        <td v-if="show">
          <button class="btn btn-sm btn-inverse" @click="removeIngredient">x</button>
        </td>
                                                
    
  
</template>

<script>
import { getIngredient } from "@/db";

export default {
  name: "Ingredient",
  data() {
    return {           
      mesure_unit:"",
      singular_mesure_unit:"",
      alternative_mesure:"",
      singular_alternative_mesure:"",       
      show:true,      
      finalIngredient:{
        name:"",
        quantity:0,
        mesure_unit:"",
        shopToBuy:""
      }
    };
  },
  emits:["updatedIngredient","removeIngredient"],
  props: {
    ingredient: Object,
    shops: Array    
  },
  watch:{
    finalIngredient:{
      deep:true,
      handler(newVal){        
        this.$emit("updatedIngredient", newVal);
      }
    },
    
    shops(val){
      if(val.length==1){
        this.finalIngredient.shopToBuy=this.shops[0].name;
      }else{
        this.finalIngredient.shopToBuy="";
      }
    }
  
  },   
  async created() {    
    var actuaIngredient=await getIngredient(this.$store.state.admin,this.$store.state.userUid,this.ingredient.name);
        
    if(this.ingredient.mesure_unit==actuaIngredient.mesure_unit||this.ingredient.mesure_unit==actuaIngredient.singular_mesure_unit){
        this.alternative_mesure=actuaIngredient.alternative_mesure;
        this.singular_alternative_mesure=actuaIngredient.singular_alternative_mesure;
        this.mesure_unit=actuaIngredient.mesure_unit;
        this.singular_mesure_unit=actuaIngredient.singular_mesure_unit;
    }else{
        this.alternative_mesure=actuaIngredient.mesure_unit;
        this.singular_alternative_mesure=actuaIngredient.singular_mesure_unit;
        this.mesure_unit=actuaIngredient.alternative_mesure;
        this.singular_mesure_unit=actuaIngredient.singular_alternative_mesure;
    }
    if(this.ingredient.mesure_unit==this.mesure_unit){      
      this.finalIngredient.mesure_unit=this.mesure_unit;      
    }else{
      this.finalIngredient.mesure_unit=this.singular_mesure_unit;      
    } 
    this.finalIngredient.name=this.ingredient.name;
    this.finalIngredient.quantity=this.ingredient.quantity;
    //cada ingredient de la suma era inferior a la unitat i la suma és igual a 1
    if(this.ingredient.quantity==1&&this.ingredient.mesure_unit==actuaIngredient.mesure_unit){
      this.finalIngredient.mesure_unit=actuaIngredient.singular_mesure_unit;
    }
    if(this.ingredient.quantity==1&&this.ingredient.mesure_unit==actuaIngredient.alternative_mesure){
      this.finalIngredient.mesure_unit=actuaIngredient.singular_alternative_mesure;
    }
    //la quantitat és difrenet a 1 i la unitat de mesura és singular
    if(this.ingredient.quantity!=1&&this.ingredient.mesure_unit==actuaIngredient.singular_mesure_unit){
      this.finalIngredient.mesure_unit=actuaIngredient.mesure_unit;
    }
    if(this.ingredient.quantity!=1&&this.ingredient.mesure_unit==actuaIngredient.singular_alternative_mesure){
      this.finalIngredient.mesure_unit=actuaIngredient.alternative_mesure;
    } 

    if(this.shops.length==1){
      this.finalIngredient.shopToBuy=this.shops[0].name;
    }
  },  
  methods: {    
    actualizeQuantity(event){      
      this.finalIngredient.quantity=event.target.value;
      if(this.finalIngredient.quantity!=1&&this.finalIngredient.mesure_unit==this.mesure_unit){      
        this.finalIngredient.mesure_unit=this.mesure_unit;  
      }else if(this.finalIngredient.quantity!=1&&this.finalIngredient.mesure_unit==this.alternative_mesure){
        this.finalIngredient.mesure_unit=this.alternative_mesure;      
      }else if(this.finalIngredient.quantity==1&&this.finalIngredient.mesure_unit==this.mesure_unit){
        this.finalIngredient.mesure_unit=this.singular_mesure_unit; 
      }else if(this.finalIngredient.quantity==1&&this.finalIngredient.mesure_unit==this.alternative_mesure){
        this.finalIngredient.mesure_unit=this.singular_alternative_mesure; 
      }else if(this.finalIngredient.quantity!=1&&this.finalIngredient.mesure_unit==this.singular_mesure_unit){
        this.finalIngredient.mesure_unit=this.mesure_unit;
      }else if(this.finalIngredient.quantity!=1&&this.finalIngredient.mesure_unit==this.singular_alternative_mesure){
        this.finalIngredient.mesure_unit=this.alternative_mesure;
      }        
    },
    removeIngredient(){
      this.show=false;
      this.$emit("removeIngredient",this.ingredient);
    }    
  },
};
</script>

<style scoped lang="scss">
  
  input[type="number"] {
   width:60px;
  }
  
  
</style>