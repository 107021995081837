<template>
  <div class="app-container text-center">
    <main class="page-home">      
      <section class="block-list">
        <div class="container">
          <h1 class="main-title">FA BONA PINTA</h1>          
          <div class="row mt-3 d-flex justify-content-center">
            <div class="col-sm-6 col-md-4 ">              
              <RecipeCard v-bind:recipeCard="recipe"></RecipeCard>
            </div>
          </div>
        </div>
      </section>      
    </main>
  </div>
</template>

<script>
import { getRecipe } from "@/db";
import RecipeCard from "@/components/RecipeCard.vue";
import swal from 'sweetalert';


export default {
  name: "Recipes",
  data() {
    return {      
      recipe: {},       
    };
  },

  components: {    
    RecipeCard
  },
  
  

  async mounted() {    
    var actualRecipe= await getRecipe(this.$store.state.admin,this.$store.state.userUid,this.$route.params.name);     
    if(actualRecipe==null){
      await swal("Tenim un problema", this.$route.params.name + " no existeix a la base de dades", "error");
      this.$router.push("/");
    } else{
      this.recipe=actualRecipe;
    } 
  },
};
</script>

<style scoped lang="scss">

h1{
  margin-top: 80px;
}
</style>