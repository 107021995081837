<template>  
  <div v-if="!$store.state.isLoggedIn">
    <div class="home-container">
      <img class="optimize img-fluid" src="../assets/home_img.webp" width="1920" height="872" alt="aliments">
      <h1 class="d-none d-lg-block home-title">QUÈ TOCA MENJAR AVUI?</h1>            
    </div>    
    <div class="container">
      <h1 class="mt-2 d-block d-lg-none small-title">QUÈ TOCA MENJAR AVUI?</h1> 
      <div class="row mt-2 mt-lg-4 text-center">
        <div class="col-6 col-lg-3 mb-4">
          <div class="card">
            <img class="optimize card-img-top" src="../assets/receptes.webp" width="1920" height="1280" alt="Card image cap">
            <div class="card-body">
              <img class="optimize card-title-img" src="../assets/icons/icon_recipes.svg" width="1025" height="540"  alt="Card image cap">
              <p class="mt-2 card-text">Introdueix receptes i ingredients</p>              
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3  mb-4">
          <div class="card">
            <img class="optimize card-img-top" src="../assets/plan.webp" width="1920" height="1280" alt="Card image cap">
            <div class="card-body">
              <img class="optimize card-title-img" src="../assets/icons/icon_month.svg" width="1009" height="892" alt="Card image cap">
              <p class="mt-2 card-text">Planifica els àpats de cada dia</p>              
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3  mb-4">
          <div class="card">
            <img class="optimize card-img-top" src="../assets/compra.webp" width="1920" height="1280" alt="Card image cap">
            <div class="card-body">
              <img class="optimize card-title-img" src="../assets/icons/icon_shopping.svg" width="4961" height="3507" alt="Card image cap">
              <p class="mt-2 card-text">Crea i envia la teva llista de la compra</p>              
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3  mb-4">
          <div class="card">
            <img class="optimize card-img-top" src="../assets/compartir.webp" width="1920" height="1280" alt="Card image cap">
            <div class="card-body">
              <img class="optimize card-title-img" src="../assets/icons/icon_share2.svg" width="3667" height="3718" alt="Card image cap">
              <p class="mt-2 card-text">Comparteix la dieta amb altres usuaris</p>              
            </div>
          </div>
        </div>
        <div class="col-12">
           <router-link to="/Register"><button class="btn btn-inverse btn-md"> Registra't</button></router-link>
        </div>
      </div>
    </div>        
  </div> 
  <div class="container px-0 px-sm-1">    
    <div class="calendar" v-if="$store.state.isLoggedIn">         
      <Calendar :view="this.view"></Calendar>      
    </div>   
  </div>
  
</template>

<script>

import Calendar from "@/components/Calendar.vue";
import swal from 'sweetalert';
import { noInformation } from "@/db";

export default {
  name: "Home",
  data() {
    return {      
      view:""
    };
  },
  components: {
    Calendar    
  },
  watch:{
    '$store.state.showInformation': function(){
      console.log("prova register");
      this.showInformation();
    },
    '$store.state.isLoggedIn': function(){
      var header = document.getElementById("header");
      function stickyfy() {
        if (window.pageYOffset > 1) {
          header.classList.add("sticky");
        }else{
          header.classList.remove("sticky");
        }
      }
      if(!this.$store.state.isLoggedIn){        
        window.onscroll = function () {
          stickyfy();
        };      
        header.classList.remove("sticky");           
      }else{
        window.onscroll = function () {};      
        header.classList.add("sticky");
      }
    }
  },
  
  async created() {    
    this.view = this.$route.params.view; 
    var header = document.getElementById("header");
    function stickyfy() {
        if (window.pageYOffset > 1) {
          header.classList.add("sticky");
        }else{
          header.classList.remove("sticky");
        }
      }
    if(!this.$store.state.isLoggedIn){        
      window.onscroll = function () {
        stickyfy();
      };      
      header.classList.remove("sticky");          
    }else{
      window.onscroll = function () {};      
      header.classList.add("sticky");
    }
    this.showInformation();         
  },
  unmounted() {
    //treu el comportament onscroll quan es demunta la pàgina
    window.onscroll = function () {      
    };
    //posa la classe sticky quan es demunta la pàgina
    var header = document.getElementById("header");
    header.classList.add("sticky");
  },  
  methods:{
    async showInformation(){      
    if(this.$store.state.showInformation&&this.$store.state.isLoggedIn){       
      await swal({
        title:"nyami et dona la benvinguda!",
        text:"Comença la teva planificació afegint una recepta. Toca dues vegades sobre un dia del calendari.",
        
      });
      var stopInformation= await swal({        
        text: "Voldràs tornar a veure aquest missatge?",
        icon: "warning",
        buttons: ["Sí", "No"],
        dangerMode: true,
      })                
      if (stopInformation) {          
        await noInformation(this.$store.state.userUid); 
        this.$store.commit('setShowInformation',false);                
      }  
    } 
    }
  }
};
</script>

<style scoped lang="scss">
.home-container{
  position: relative;
}
.home-title{
  position:absolute;
  top:13%;
  left:13%;
  right:55%;
  color: white;  
  font-size: 5vw;
  font-weight: 800;
  z-index: 2;
}
.small-title{
  text-align: center;
  color: $primary;  
  font-size: 5vw;
  font-weight: 800;
  z-index: 2;
}
// Estils
.card{
  border-color: $primary;
  border-width: 1px;  
  min-height: 200px;
}
@media (min-width: 544px) {  
  .card {min-height: 310px;} 
}

.card-title-img{
  height: 50px;
}
.calendar{
  margin-top: 80px;  
}

</style>