<template>
  <div class="container mt-5">
    <div class="text-center mt-5">
      <img class="logo" alt="logo" src="@/assets/icon_nyami.svg" />
      <h1>404</h1>
      <h2>Ep!! Per aquí no anem pas bé! La pàgina que busques no existeix.</h2>
      <h2>
        Però segur que t'agradarà descobrir <router-link to="/"><a>nyami</a></router-link>
      </h2>
    </div>        
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped lang="scss">
a{
  color:$primary;
  text-decoration: none;
}
a:hover{
  color:$primary;
  text-decoration: underline;  
}
h1 {
  font-weight: 900;  
  font-size: 52px;
}
.logo {
  width: 120px;
  margin-bottom: 20px;
}
</style>
