<template>
  <div class="container mt-5">
    <div class="row mt-5">
        <div class="col-md-5 d-flex align-items-center mb-4">
            <img alt="logo" src="@/assets/icon_nyami.svg" />          
      
        </div>
        <div class="col-md-7">
            <h1 class="text-center">SOBRE NOSALTRES</h1>
            <p>En primer lloc, degut als horaris de la meva feina, i en segon, perquè m'agrada cuinar, a casa meva sempre he estat jo el que cuina. I, tot i que no tinc cap problema en posar-me davant dels fogons, em fa molta mandra decidir què toca per menjar. Tenint això com a premissa, de cop, va aparèixer la pandèmia del Coronavirus.</p>           
            <p>I va ser llavors, durant l'etapa de confinament total, que amb la meva parella vam començar a assentar-nos un dia a la setmana i decidir què menjaríem cada dia durant la següent setmana. Amb això fèiem una llista dels ingredients que necessitàvem i la passàvem per WhatsApp al botiguer del supermercat del poble perquè ens ho portés a casa. Això va començar amb una quadrícula en una llibreta i un bolígraf i va evolucionar a una taula compartida als fulls de càlcul de Google drive.</p>
            <p>Un cop es van anar relaxant les restriccions, ens havíem acostumat tant a fer això que vam seguir amb la mateixa pràctica i no teníem cap intenció d'eliminar-la. Però si que pensàvem que es podia millorar i optimitzar i que moltes famílies o persones també en podrien fer ús.</p>
            <p>I tenint com a base tot el que s'ha parlat fins al moment neix <span>nyami</span>. El nom sorgeix per una part de “Nyam-nyam”, que és una expressió que s'utilitza al parlar amb nens i nenes molt petits per evocar l'acció de menjar. Per altra banda hi ha l'expressió anglesa yummy, que dona nom a algun element molt agradable als sentits, sobretot al gust. O sigui, alguna cosa deliciosa. Aquest yummy té una pronunciació en anglès que s'acosta molt a la del nom de l'aplicació en català.</p>
            <p><span>nyami</span>, es podria descriure com una onomatopeia per expressar que un menjar ens encanta i és el nom de la nostra aplicació que permet elaborar planificacions de menús i registre dels mateixos, alhora que elabora llistes de la compra pels dies escollits i permet compartir la teva dieta amb altres usuaris.</p>            
        </div>
      
    </div>        
    
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped lang="scss">
span{
    color:$primary;
    font-weight: 800;
}
</style>