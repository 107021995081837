<template>  
    <div class="container d-flex justify-content-center mt-5">
        <div class="col-md-6 text-center mt-5">
            <img class="logo" alt="logo" src="@/assets/icon_nyami.svg" />
            <h1 class="main-title">COM ET PODEM AJUDAR?</h1>
            <p class="auth-intro mb-4">
                No dubtis a enviar-nos un missatge!
            </p>

            <form v-on:submit.prevent="send">
                <div class="form-group mb-4">
                <label class="form-label">Nom</label>
                <input
                    v-model="name"                    
                    type="text"            
                    class="form-control"
                    required
                />
                </div>
                <div class="form-group mb-4">
                <label class="form-label">Email</label>
                <input                    
                    v-model="email"
                    type="email"
                    class="form-control"
                    required
                />
                </div>
                <div class="form-group mb-4">
                <label class="form-label">Missatge</label>
                <textarea
                    v-model="message"
                    placeholder="Ens interessa molt tot el què ens puguis dir."
                    cols="30"
                    rows="5"
                    class="form-control"         
                    required   
                />          
                </div>
                <button type="submit" class="btn btn-inverse btn-md">Enviar</button>        
            </form> 
        </div>
           
    </div>
  
</template>

<script>

import emailjs from 'emailjs-com';
import swal from 'sweetalert';         

export default {
  name: "contact",
  data: function () {
    return {
      name:"",
      email: "",
      message: ""
    };
  },
  methods: {
      send() { 
            try {                 
                emailjs.init("l1m8KibQUvjmCt_Ew");
                emailjs.send("service_gft8bzk","template_qkf26x5",{ from_name: this.name, message: this.message, reply_to: this.email });                
                swal("Fet!", "El missatge s'ha enviat correctament", "success");
                this.name = "";
                this.email = "";
                this.message = "";
            }catch(error) { 
                console.log({error}) 
            }                        
        }
  }
};
</script>

<style scoped lang="scss">
.logo{
      width:120px;
      margin-bottom: 20px;
    }

</style>