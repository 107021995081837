<template>
  <div class="vld-parent">
        <loading v-model:active="isLoading"/>         
  </div>
  <main class="page-register">
    <section class="box-auth">
      <img class="logo" alt="logo" src="@/assets/icon_nyami.svg" />
      <h1 class="main-title">REGISTRE</h1>
      <p class="auth-intro">
        Registra't i podràs treure tot el partit de l'aplicació
      </p>

      <form>
        <div class="form-group">
          <label class="form-label" for="username">Email</label>
          <input
            v-model="email"
            placeholder="Correu electrònic"
            type="email"
            id="email"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label class="form-label" for="password">Contrasenya</label>
          <input
            v-model="password"
            placeholder="Contrasenya"
            type="password"
            id="password"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label class="form-label" for="password">Confirmar contrasenya</label>
          <input
            v-model="confirm"
            placeholder="Contrasenya"
            type="password"
            id="confirm"
            class="form-control"
            required
          />
        </div>
        <button v-on:click.prevent="register" class="btn btn-inverse btn-md">
          Som-hi
        </button>
      </form>

      <p class="auth-bottom">
        Ja tens un compte?
        <router-link to="/Login">Inicia la sessió</router-link>
      </p>
    </section>
  </main>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"; // completar import
import { newUserDB } from "@/db";
import swal from 'sweetalert';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  name: "register",
  data: function () {
    return {
      isLoading:false,
      email: "",
      password: "",
      confirm:""
    };
  },
  components: {    
    Loading
  },
  methods: {
    async register(){
      try{
        const auth = getAuth();
        if(this.password==this.confirm){          
          const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password)
          const user = userCredential.user;
          this.isLoading=true;
          await newUserDB(user.uid,user.email);
          this.isLoading=false;          
          this.$router.push("/");
        }else{
          swal("No anem bé!", "La contrasenya i la confirmació no coincideixen!", "error");
        }
      }catch (e) {
        const errorCode = e.code;
        if(errorCode=="auth/email-already-in-use"){
          swal("No anem bé!", "Ja existeix un usuari amb aquest email!" , "error"); 
        }else if(errorCode=="auth/weak-password"){
          swal("No anem bé!", "La contrasenya no és prou segura!", "warning");
        }else{
          swal("No anem bé!", errorCode , "error"); 
        }                  
      }      
    }    
  },
};
</script>

<style scoped lang="scss">
a{
    color:$primary;
    text-decoration: none;
}
a:hover{
    color:$primary;
    text-decoration: none;
}
</style>