import { createApp } from "vue";
import { createStore } from 'vuex'
import App from "./App.vue";
import router from "./router";
import "./firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.scss";
import "./registerServiceWorker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import createPersistedState from "vuex-persistedstate";

// Create a new store instance.
const store = createStore({
    state () {
      return {        
        isLoggedIn:false,
        userUid:"",
        admin:false,
        userMail:"",
        showInformation:false
      }
    },
    plugins: [createPersistedState()],
    mutations: {      
      setIsLoggedIn(state,isLoggedIn){
          state.isLoggedIn=isLoggedIn;          
      },
      setUserUid(state,userUid){
        state.userUid=userUid;              
      },
      setAdmin(state,admin){
        state.admin=admin;
      },
      setShowInformation(state,information){
        state.showInformation=information;
      },      
      setUserMail(state,userMail){
        state.userMail=userMail;  
      }
    },
    
  })

const app = createApp(App);
app.use(router);
app.mount("#app");
app.use(store);
app.component("v-select", vSelect);

